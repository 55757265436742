/* eslint-disable react/display-name */

import React, { memo, useState, useEffect } from 'react';
import Checkbox from '../Filters/FilterCheckbox';
import { sortString } from '../../Config/utils';
import { DoctFreeSoloSearchInput } from '../Form';
import ListIcon from '../Filters/ListIcon';
import { DoctButton, DoctTypography } from '@doct-react/core';
import DoctLoading from '../Loadings/DoctLoading';
import { SkeletonTabViewVertical } from '../Skeleton/Components/SkeletonElements';
import { DoctSkeletonCheckboxWithLabel } from '../Skeleton';

interface ResponsiveFilterListProps {
    handleChange: (e, category) => void;
    filters: Record<string, unknown>[];
    filtersListArray?: any[];
    handleCloseTag: (chipCategory: string, chipValue: unknown) => void;
    clearAllFilters: () => void;
    initialState?: boolean[];
    responsive?: boolean;
    className?: any;
    count?: any;
    primaryButtonPressed?: () => void;
    loading?: boolean;
    primaryButtonText?: string;
    buttonVariant?: 'contained' | 'text' | 'outlined';
    buttonType?:
        | 'primary'
        | 'secondary'
        | 'semantic-danger'
        | 'semantic-success'
        | 'semantic-warning'
        | 'semantic-info'
        | 'inverse';
    disabled?: boolean;
    truncateOn?: number;
}

const TabViewFilterList: React.FC<ResponsiveFilterListProps> = memo(
    ({
        filters,
        filtersListArray,
        handleChange,
        className,
        count,
        primaryButtonPressed,
        loading = false,
        primaryButtonText = 'Apply Filter',
        buttonVariant = 'outlined',
        buttonType = 'primary',
        disabled = false,
        truncateOn,
    }) => {
        const [isSetInitialFilter, setIsSetInitialFilter] = useState(false);

        const [showFilterOfKey, setShowFilterOfKey] = useState([]);
        const [keyBasedFilterFilterKey, setKeyBasedFilterFilterKey] = useState('');

        const [filtersWithSearch, setFiltersWithSearch] = useState([]);
        const [showSearch, setShowSearch] = useState(false);

        const [isToggle, setIsToggle] = useState(false);

        const [value, setValue] = React.useState(null);
        const [inputValue, setInputValue] = React.useState('');

        // Initially set first filters array to render
        // Once it'set, prevent to set again when filterArray is changed
        useEffect(() => {
            if (isSetInitialFilter) return;

            if (filtersListArray.length) {
                const arrayFindWithVal = filtersListArray.find((el) => el.array?.length);
                if (arrayFindWithVal?.filterKey) {
                    manageFilter(arrayFindWithVal.filterKey, arrayFindWithVal.array);
                    setIsSetInitialFilter(true);
                }
            }
        }, [filtersListArray]);

        useEffect(() => {
            if (inputValue === undefined || inputValue === null || !isSetInitialFilter) return;

            const results =
                inputValue && inputValue.length >= 1
                    ? showFilterOfKey.filter((item) => {
                          return sortString(item.value).includes(sortString(inputValue));
                      })
                    : showFilterOfKey;
            setFiltersWithSearch(results);
        }, [inputValue]);

        useEffect(() => {
            if (inputValue) return;
            if (filtersWithSearch?.length > 7) {
                setShowSearch(true);
            } else {
                setShowSearch(false);
            }
        }, [isToggle, filtersWithSearch?.length]);

        const manageFilter = (filterKey, array) => {
            setShowFilterOfKey(array);
            setFiltersWithSearch(array);
            setKeyBasedFilterFilterKey(filterKey);
        };

        const handelFilterTitleClick = (filterKey, array) => {
            manageFilter(filterKey, array);
            setInputValue('');
            setIsToggle(!isToggle);
        };

        return (
            <div className={`tab-view-filter position-relative d-flex ${className}`}>
                <ul className="mr-2 tab-view-filter-tab-container">
                    {loading ? (
                        <SkeletonTabViewVertical />
                    ) : filtersListArray.length ? (
                        filtersListArray.map(({ collapseTitle = '', filterKey = '', array = [] } = {}) => {
                            if (!array?.length) return null;
                            return (
                                <li
                                    key={collapseTitle}
                                    className={
                                        keyBasedFilterFilterKey == filterKey
                                            ? 'bg-white tab-view-filter-tab tab-view-filter-tab-active global-border-radius-tr global-border-radius-br cursor-pointer'
                                            : 'tab-view-filter-tab global-border-radius-tr global-border-radius-br cursor-pointer'
                                    }
                                    onClick={() => handelFilterTitleClick(filterKey, array)}
                                >
                                    {collapseTitle}
                                </li>
                            );
                        })
                    ) : null}
                </ul>
                <div className="tab-view-filter-tab-content-wrapper">
                    <div className="ml-2">
                        {showSearch && (
                            <>
                                <DoctFreeSoloSearchInput
                                    name="filterSearch"
                                    options={[]}
                                    onInputChangeHandler={(val) => {
                                        setInputValue(val);
                                    }}
                                    onChangeHandler={(val) => setValue(val)}
                                    inputValue={inputValue}
                                    value={value}
                                    inverseBackground
                                    noRadial
                                    placeholder="Search"
                                />
                            </>
                        )}
                        <div className="tab-view-filter-search-gap"></div>
                        <div className="overflow-auto tab-view-filter-content-listing">
                            {loading && (
                                <>
                                    {[...Array(10)].map(() => (
                                        <DoctSkeletonCheckboxWithLabel />
                                    ))}
                                </>
                            )}
                            {!loading &&
                                filtersWithSearch.map(({ id, value, count }, i) => {
                                    return (
                                        <Checkbox
                                            key={i}
                                            onChange={handleChange}
                                            id={id}
                                            name={value}
                                            category={keyBasedFilterFilterKey}
                                            count={count}
                                            isChecked={
                                                filters.some((check) => check[keyBasedFilterFilterKey] === value) ||
                                                false
                                            }
                                            truncateOn={truncateOn}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <div className="position-absolute tab-view-filter-sticky-row align-items-center bg-white d-flex justify-content-between p-3">
                    <span className="tab-view-filter-count-box bg-white-low-opacity bg-white-low-opacity global-border-radius py-1 px-2 d-inline-flex align-items-center justify-content-center">
                        {loading ? (
                            <>
                                <DoctLoading type="circular" spinnerSize={20} />
                                <DoctTypography variant="textLabel1" className="my-0 ml-1 text-grey-400">
                                    Loading...
                                </DoctTypography>
                            </>
                        ) : (
                            <>
                                <ListIcon fill={`${count ? '#00a0c0' : '#EA4335'}`} />
                                <DoctTypography variant="textLabel1" className={`m-0 ${count ? '' : 'text-danger'}`}>
                                    {count || 'No'} results
                                </DoctTypography>{' '}
                            </>
                        )}
                    </span>
                    <DoctButton
                        variant={buttonVariant}
                        text={primaryButtonText}
                        size="medium"
                        onButtonClickHandler={() => {
                            if (primaryButtonPressed) {
                                primaryButtonPressed();
                            }
                        }}
                        type={buttonType}
                        disabled={disabled}
                    />
                </div>
            </div>
        );
    },
);

export default TabViewFilterList;
