import React, { MouseEventHandler } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { listItems } from './static/data';
import DialogWrapper from './DoctUserMenuWrapper';
import { Divider } from '@mui/material';

interface IListItems {
    handleLogout: () => void;
}

const ListItems: React.FC<IListItems> = ({ handleLogout }) => {
    return (
        <>
            {listItems?.map((item, index) => {
                return (
                    <>
                        <ListItem disablePadding key={index} className="doct-user-menu-list-item">
                            <ListItemButton
                                onClick={() => {
                                    let url = item.url;
                                    if (
                                        typeof window.DoctUrls !== 'undefined' &&
                                        window.DoctUrls.hasOwnProperty('doct_account_url')
                                    ) {
                                        url = window.DoctUrls.doct_account_url + '/' + url;
                                    }
                                    window.location.href = `${url}?continue_url=${window.location.href}`;
                                }}
                                className="doct-user-menu-profile-list-item-button"
                            >
                                <DialogWrapper
                                    listItemIcon={item.icon}
                                    listItemText={item.name}
                                    listItemColor={item.color}
                                />
                            </ListItemButton>
                        </ListItem>
                        {item.name === 'My Account' || item.name === 'My Profile' ? (
                            <Divider className="doct-user-menu-divider" />
                        ) : (
                            ''
                        )}
                    </>
                );
            })}
            <Divider className="doct-user-menu-divider" />
            <ListItem disablePadding className="doct-user-menu-list-item">
                <ListItemButton onClick={() => handleLogout()} className="doct-user-menu-profile-list-item-button">
                    <DialogWrapper listItemIcon="logoutIcon" listItemText="Log out" listItemColor="" />
                </ListItemButton>
            </ListItem>
        </>
    );
};

export default ListItems;
