import React, { ReactElement } from 'react';

import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import EventIcon from '@mui/icons-material/Event';
import { InputAdornment } from '@mui/material';
import { Controller, Control, FieldError } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';

interface Props {
    label?: string;
    id: string;
    name: string;
    control: Control<Record<string, any>>;
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    isErrors?: DeepMap<Record<string, any>, FieldError>;
    touched?: DeepMap<Record<string, any>, true>;
    validationRules?: any;
    defaultValue?: any;
    showStar?: boolean;
    keepOpenPicker?: boolean;
    inputFormat?: string;
}

export default function DoctDateRangePicker({
    label,
    id,
    name,
    disabled = false,
    readOnly = false,
    className,
    control,
    isErrors,
    touched,
    defaultValue = [null, null],
    validationRules,
    showStar = true,
    inputFormat,
    ...rest
}: Props): ReactElement {
    return (
        <Controller
            id={id}
            rules={validationRules}
            render={({ value, onChange }) => (
                <div
                    className={`doct-date-range-picker ${className} ${readOnly ? ' doct-read-only-input' : ''}${
                        label ? ' ' : ' none-doct-input-label'
                    }`}
                >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                            inputFormat={inputFormat}
                            disabled={disabled}
                            startText="Start date"
                            endText="End date"
                            value={value}
                            onChange={(newValue: any) => {
                                if (onChange) {
                                    onChange(newValue);
                                }
                            }}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField
                                        {...startProps}
                                        variant="filled"
                                        placeholder=""
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EventIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        {...endProps}
                                        variant="filled"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EventIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider>
                </div>
            )}
            defaultValue={defaultValue}
            name={name}
            control={control}
            {...rest}
        />
    );
}
