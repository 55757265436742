import React from 'react';
import { DoctButton, DoctTypography } from '@doct-react/core';

import { FeatureBoxResponsive, HeadingAndContent, SharePlayAppStore } from './Components';

import '../DoctNavigationMenuBranding.scss';
import { DoctNavigationMenuEnterpriseAndBrandingAppResponsiveProps } from '../interfaces';

const DoctNavigationMenuEnterpriseAndBrandingAppResponsive: React.FC<
    DoctNavigationMenuEnterpriseAndBrandingAppResponsiveProps
> = ({ productList }) => {
    return (
        <div className="navigation-menu responsive-navigation-menu px-3 d-md-none bg-white">
            <div className="d-flex flex-column flex-sm-row">
                <div className="d-flex flex-wrap w-100">
                    <HeadingAndContent heading="Our offerings" isBrandingView>
                        {window?.DoctUrls?.doct_course_url && (
                            <FeatureBoxResponsive
                                iconName="coursesApp"
                                title="Courses"
                                href={window.DoctUrls.doct_course_url}
                            />
                        )}
                        {window?.DoctUrls?.doct_jobs_url && (
                            <FeatureBoxResponsive
                                iconName="jobsApp"
                                title="Jobs"
                                href={window.DoctUrls.doct_jobs_url}
                            />
                        )}
                        {window?.DoctUrls?.doct_event_url && (
                            <FeatureBoxResponsive
                                iconName="eventsApp"
                                title="Events"
                                href={window.DoctUrls.doct_event_url}
                            />
                        )}
                        <div
                            className={`navigation-menu-divider-horizontal navigation-menu-divider-horizontal-my-midium navigation-menu-divider w-100 d-sm-none`}
                        />
                    </HeadingAndContent>
                </div>
                <div className="d-flex w-100">
                    <div
                        className={`navigation-menu-divider navigation-menu-divider-vertical d-none d-sm-block mx-sm-2`}
                    />

                    <div className="navigation-menu-tools px-sm-3">
                        <HeadingAndContent heading="Tools" isBrandingView>
                            {window?.DoctUrls?.doct_log_book_url && (
                                <FeatureBoxResponsive
                                    iconName="logbookOutline"
                                    title="Logbook"
                                    href={window.DoctUrls.doct_log_book_url}
                                />
                            )}
                        </HeadingAndContent>
                    </div>
                    <div className={`navigation-menu-divider navigation-menu-divider-vertical mx-sm-2`} />

                    <div className={`mt-auto navigation-menu-knowledge`}>
                        <HeadingAndContent heading="Knowledge" isBrandingView>
                            <div className="d-flex">
                                {window?.DoctUrls?.doct_blogs_url && (
                                    <FeatureBoxResponsive
                                        iconName="blogsIconOutline"
                                        title="Blogs"
                                        href={window.DoctUrls.doct_blogs_url}
                                    />
                                )}
                                {window?.DoctUrls?.doct_news_url && (
                                    <FeatureBoxResponsive
                                        iconName="newsOutline"
                                        title="News"
                                        href={window.DoctUrls.doct_news_url}
                                    />
                                )}
                            </div>
                        </HeadingAndContent>
                    </div>
                </div>
            </div>
            <div className="navigation-menu-divider-horizontal navigation-menu-divider-horizontal-my-large navigation-menu-divider"></div>

            <HeadingAndContent heading="For Organizations" isBrandingView>
                <div className="d-flex justify-content-sm-center flex-wrap">
                    {productList.map((item) => {
                        return (
                            item?.href && (
                                <FeatureBoxResponsive
                                    {...item}
                                    key={item?.href}
                                    className={`feature-box-fixed-width mb-3`}
                                />
                            )
                        );
                    })}
                    <FeatureBoxResponsive
                        href={
                            window?.DoctUrls?.doct_enterprise_solutions_url &&
                            window.DoctUrls.doct_enterprise_solutions_url
                        }
                        title="Docthub Enterprise"
                        iconName="enterpriseFilled"
                        className={`feature-box-fixed-width mb-3`}
                    />
                    <a
                        href={`${window.DoctUrls?.doct_enterprise_url}/dashboard`}
                        className="responsive-navigation-menu-go-to-dashboard d-flex flex-column align-items-center mt-sm-2"
                    >
                        <DoctTypography variant="textLabel2" className="text-grey-600">
                            Go to your Enterprise Dashboard
                        </DoctTypography>
                        <div className="navigation-menu-btn-primary700">
                            <DoctButton text="Enterprise Login >" size="medium" />
                        </div>
                    </a>
                </div>
            </HeadingAndContent>
            <div className="navigation-menu-divider-horizontal navigation-menu-divider-horizontal-my-large mb-5 navigation-menu-divider"></div>
            <div className="d-flex flex-column">
                <nav className="responsive-navigation-menu-footer-bottom-links d-flex flex-wrap flex-sm-column">
                    {window?.DoctUrls.doct_brand_url && (
                        <>
                            <a href={`${window.DoctUrls.doct_brand_url}/about-us`}>
                                <DoctTypography variant="subtitle1" className="text-primary-500 mt-0 text-sm-center">
                                    {'About us>'}
                                </DoctTypography>
                            </a>
                            <a href={`${window?.DoctUrls.doct_brand_url}/contact-us`}>
                                <DoctTypography
                                    variant="subtitle1"
                                    className="text-primary-500 mt-0 mb-3 text-sm-center"
                                >
                                    {'Contact us>'}
                                </DoctTypography>
                            </a>
                        </>
                    )}
                    {window?.DoctUrls?.doct_enterprise_solutions_url && (
                        <a href={`${window?.DoctUrls.doct_enterprise_solutions_url}`}>
                            <DoctTypography variant="subtitle1" className="text-primary-500 mt-0 mb-3 text-sm-center">
                                {'Docthub Enterprise>'}
                            </DoctTypography>
                        </a>
                    )}
                </nav>
                <div className="responsive-navigation-menu-download-links d-flex flex-sm-column mt-sm-5">
                    <DoctTypography variant="subtitle2" className="text-grey-800 font-weight-bold my-0 text-sm-center">
                        Download
                        <DoctTypography variant="subtitle3" className="text-grey-800 my-0">
                            Docthub Mobile App
                        </DoctTypography>
                    </DoctTypography>
                    <div className="responsive-navigation-menu-share-gap d-flex justify-content-center ml-4 ml-sm-0">
                        <SharePlayAppStore classNamePlayStoreLinkMargin="ml-sm-0" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DoctNavigationMenuEnterpriseAndBrandingAppResponsive;
