import React from 'react';

import {
    BaseNavigationMenu,
    EntperiseNavigation,
    HeadingAndContent,
    LayoutNavigationMenuBranding,
    LinkItem,
    NavigationMenuContainer,
    ProductGridLayoutBrandingPage,
} from './Components/Components';

import DoctNavigationMenuEnterpriseAndBrandingAppResponsive from './Components/DoctNavigationMenuEnterpriseAndBrandingAppResponsive';

import './DoctNavigationMenu.scss';

interface DoctNavigationMenuUserAppBrandingProps {
    className?: string;
    onClosePressed?: () => void;
    adjustSpacing?: number;
}
const DoctNavigationMenuBrandingApp: React.FC<DoctNavigationMenuUserAppBrandingProps> = ({
    className = 'd-none d-md-block',
    onClosePressed,
    adjustSpacing,
}) => {
    const productList = [
        {
            href: window?.DoctUrls?.doct_institutes_url,
            title: 'List a Course',
            description: 'Institute Dashboard',
            iconName: 'courseEnterpriseFilled',
        },
        {
            href: window?.DoctUrls?.doct_recruiters_url,
            title: 'Post a Job',
            description: 'Recruiter Dashboard',
            iconName: 'jobsEnterpriseFilled',
        },
        {
            href: window?.DoctUrls?.doct_event_organizers,
            title: 'Create an Event',
            description: 'Events Dashboard',
            iconName: 'eventEnterpriseFilled',
        },
        {
            href: window?.DoctUrls?.doct_membership_url,
            title: 'Membership Management',
            description: 'Association Management Solutions',
            iconName: 'membershipOutline',
        },
    ];

    if (window.innerWidth < 1024) {
        return <DoctNavigationMenuEnterpriseAndBrandingAppResponsive productList={productList} />;
    }

    return (
        <NavigationMenuContainer className={className} adjustSpacing={adjustSpacing}>
            <BaseNavigationMenu onClosePressed={onClosePressed}>
                <LayoutNavigationMenuBranding branding>
                    <HeadingAndContent heading="Enterprise Solutions">
                        <ProductGridLayoutBrandingPage>
                            {productList.map((item, index) => {
                                return (
                                    item?.href && <LinkItem {...item} key={item?.href} className={`product-${index}`} />
                                );
                            })}
                        </ProductGridLayoutBrandingPage>
                        <EntperiseNavigation />
                    </HeadingAndContent>
                </LayoutNavigationMenuBranding>
            </BaseNavigationMenu>
        </NavigationMenuContainer>
    );
};

export default DoctNavigationMenuBrandingApp;
