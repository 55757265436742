import React, { Component, ReactNode } from 'react';

import './DoctSidebarMenu.scss';

interface Props {
    children?: ReactNode;
    className?: string;
    menuItems: { link: any }[];
    responsive?: boolean;
}

export default class DoctSidebarMenu extends Component<Props> {
    render(): JSX.Element {
        const { className, menuItems = [], responsive = false } = this.props;

        return (
            <>
                {responsive ? (
                    <div
                        className={`doct-sidebar doct-sidebar-responsive d-block d-md-none${
                            className ? ` ${className}` : ''
                        }`}
                    >
                        <div className="container">
                            <ul className="doct-sidebar-menu">
                                {menuItems.map((item, id) => (
                                    <li key={id} className={`doct-sidebar-menu-item`}>
                                        {item.link}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ) : (
                    <aside className={`doct-sidebar d-none d-md-block${className ? ` ${className}` : ''}`}>
                        <ul className="doct-sidebar-menu">
                            {menuItems.map((item, id) => (
                                <li key={id} className={`doct-sidebar-menu-item`}>
                                    {item.link}
                                </li>
                            ))}
                        </ul>
                    </aside>
                )}
            </>
        );
    }
}
