import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { DoctIcon, DoctTypography } from '@doct-react/core';

interface IDialogWrapper {
    listItemIcon: string;
    listItemText: string;
    listItemColor: string;
}

const DialogWrapper: React.FC<IDialogWrapper> = ({ listItemIcon, listItemText, listItemColor }) => {
    return (
        <>
            <div className="d-flex justify-content-between align-items-center w-100">
                <div className="d-flex align-items-center">
                    <ListItemIcon>
                        <DoctIcon width="24" height="24" fill={listItemColor} name={listItemIcon} />
                    </ListItemIcon>
                    <DoctTypography variant="textLabel1" textTransform="normal" className="text-grey-800 my-0">
                        {listItemText}
                    </DoctTypography>
                </div>
                {listItemText === 'Log out' ? (
                    ''
                ) : (
                    <DoctIcon width="18" height="18" fill="#aaa" name="arrowForwardBig" />
                )}
            </div>
        </>
    );
};

export default DialogWrapper;
