import React, { ReactElement } from 'react';
// import DoctIconButton from '';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DoctIcon, DoctIconButton } from '@doct-react/core';
import ListItemIcon from '@mui/material/ListItemIcon';
import './DoctActionMenu.scss';

const ITEM_HEIGHT = 48;

interface Props {
    onClick?: (option) => void;
    options: { title: string; icon?: { name?: string; width?: string }; className?: string }[];
    className?: string;
    btnType?:
        | 'secondary'
        | 'primary'
        | 'semantic-danger'
        | 'semantic-success'
        | 'semantic-warning'
        | 'semantic-info'
        | 'inverse';
}

function DoctActionMenu({ onClick, options, className, btnType = 'secondary' }: Props): ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const clickHandler = (e, option) => {
        e.stopPropagation();
        handleClose();
        onClick ? onClick(option) : null;
    };

    return (
        <div className={className}>
            <div onClick={handleClick} className="d-inline-block" style={{ width: 'auto' }}>
                <DoctIconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    size="medium"
                    type={btnType}
                    icon="moreVertical"
                />
            </div>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options.map((option, id) => (
                    <MenuItem
                        data-id={option.title}
                        className={`doct-action-menu-item ${option.className ? option.className : ''}`}
                        key={id}
                        selected={option.title === 'Pyxis'}
                        onClick={(e) => clickHandler(e, option)}
                    >
                        {option.icon && (
                            <ListItemIcon>
                                <DoctIcon name={option.icon.name} width={option.icon.width || '20px'} />
                            </ListItemIcon>
                        )}
                        {option.title}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default DoctActionMenu;
