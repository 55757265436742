// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validateSize = (imageFile, MAX_FILE_SIZE_KB, MAX_FILE_SIZE_MB) => {
    if (imageFile && imageFile.size) {
        const imageFileKb = imageFile.size / 1024;
        if (imageFileKb > MAX_FILE_SIZE_KB) {
            typeof window !== 'undefined' && window.alert(`File size should be less then ${MAX_FILE_SIZE_MB} mb`);
            return false;
        }
        return true;
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const maxSelectFile = (event, MAX_FILES) => {
    const files = event && event.target.files;
    if (files.length > MAX_FILES) {
        event.target.value = null;
        return false;
    }
    return true;
};
