import React, { useReducer } from 'react';
// import dynamic from 'next/dynamic';
// import PropTypes from 'prop-types';
import Collapse from '@kunukn/react-collapse';

import { DoctTypography, DoctIcon } from '@doct-react/core';

// const DoctTypography = dynamic(() => import('@doct-react/core').then((mod) => mod.DoctTypography), { ssr: false });

// const DoctIcon = dynamic(() => import('@doct-react/core').then((mod) => mod.DoctIcon), { ssr: false });

const ACTIONS = {
    TOGGLE: 'TOGGLE',
};

// const initialState = [true, true, false, false, false, false]

function reducer(state, { type, index }) {
    switch (type) {
        case ACTIONS.TOGGLE:
            state[index] = !state[index];
            return [...state];

        default:
            throw new Error();
    }
}

function Block({ isOpen, title, onToggle, children }) {
    return (
        <>
            <div className="collapsible-header d-flex justify-content-between align-items-center" onClick={onToggle}>
                <DoctTypography className="text-grey-800 m-0" variant="subtitle2">
                    {title}
                </DoctTypography>
                <DoctIcon className="text-grey-600" name="down" width="20" />
            </div>
            <Collapse className="collapsible-content-wrapper" isOpen={isOpen}>
                {children}
            </Collapse>
        </>
    );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Collapsible = ({ children, title, index, initialState }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <div className={`collapsible${state[index] ? ' active' : ''}`}>
            <Block title={title} isOpen={state[index]} onToggle={() => dispatch({ type: ACTIONS.TOGGLE, index })}>
                {children}
            </Block>
        </div>
    );
};

Collapsible.propTypes = {
    // children: PropTypes.any.isRequired,
    // title: PropTypes.string.isRequired
};

export default Collapsible;
