/* eslint-disable react/display-name */
import React, { memo, useContext } from 'react';

import { DoctCol, DoctRow, DoctTypography } from '@doct-react/core';
import SingleFilter from '../Filters/SingleFilter';
import FilterTags from './FilterTags';

import './Filters.scss';
import TabViewFilterList from '../TabViewFilterList/TabViewFilterList';
import InfiniteScrollProvider from './InfiniteScroll.context';

interface FiltersListProps {
    handleChange: (e, category) => void;
    filters: Record<string, unknown>[];
    filtersListArray?: any[];
    handleCloseTag: (chipCategory: string, chipValue: unknown) => void;
    clearAllFilters: () => void;
    initialState?: boolean[];
    responsive?: boolean;
    className?: any;
    count?: any;
    primaryButtonPressed?: () => void;
    loading?: boolean;
    primaryButtonText?: string;
    buttonVariant?: 'contained' | 'text' | 'outlined';
    buttonType?:
        | 'primary'
        | 'secondary'
        | 'semantic-danger'
        | 'semantic-success'
        | 'semantic-warning'
        | 'semantic-info'
        | 'inverse';
    disabled?: boolean;
    truncateOn?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FiltersList: React.FC<FiltersListProps> = memo(
    ({
        filtersListArray = [],
        handleChange,
        filters,
        clearAllFilters,
        handleCloseTag,
        initialState,
        responsive,
        className = '',
        count = '',
        primaryButtonPressed,
        loading = false,
        primaryButtonText = 'Apply Filter',
        buttonVariant = 'outlined',
        buttonType = 'primary',
        disabled = false,
        truncateOn,
    }) => {
        const perRowItems = Math.round(filtersListArray.length / 2) || 0;
        const totalFilters = filtersListArray.length || 0;

        function CollapsibleWrapper({ item }) {
            return (
                item.array.length >= 1 && (
                    // <InfiniteScrollProvider sourceData={item.array} key={`${item?.array}-${index}`}>
                    <SingleFilter
                        initialState={initialState}
                        filtersListArray={filtersListArray}
                        item={item}
                        index={item.index}
                        showFilter={item?.array?.length > 7}
                        array={item.array}
                        filters={filters}
                        handleChange={handleChange}
                        truncateOn={truncateOn}
                    />
                    // </InfiniteScrollProvider>
                )
            );
        }

        if (responsive) {
            return (
                <TabViewFilterList
                    initialState={initialState}
                    filtersListArray={filtersListArray}
                    filters={filters}
                    handleChange={handleChange}
                    handleCloseTag={handleCloseTag}
                    clearAllFilters={clearAllFilters}
                    className={className}
                    count={count}
                    primaryButtonPressed={primaryButtonPressed}
                    loading={loading}
                    primaryButtonText={primaryButtonText}
                    buttonVariant={buttonVariant}
                    buttonType={buttonType}
                    disabled={disabled}
                    truncateOn={truncateOn}
                />
            );
        }

        return (
            <>
                <div className="filters-tag filters-tag-spacing">
                    <div className="d-flex align-items-center justify-content-between">
                        <DoctTypography variant="subtitle2" className="m-0 text-grey-800">
                            Filters
                        </DoctTypography>
                        {filters.length >= 1 && (
                            <div onClick={clearAllFilters} className="text-primary filter-link">
                                Clear All
                            </div>
                        )}
                    </div>
                    <div className="doct-chip-wrapper">
                        <FilterTags filters={filters} handleCloseTag={handleCloseTag} />
                    </div>
                </div>
                <DoctRow>
                    <DoctCol xs={12} sm={6} md={12}>
                        <>
                            {filtersListArray &&
                                filtersListArray.slice(0, perRowItems).map((item: any, i: number) => (
                                    <InfiniteScrollProvider
                                        sourceData={item.array}
                                        key={`scroller-${item?.array.length}-${i}`}
                                    >
                                        <CollapsibleWrapper
                                            item={item}
                                            key={`collapse-wrapper-${item?.array.length}-${i}`}
                                        />
                                    </InfiniteScrollProvider>
                                ))}
                        </>
                    </DoctCol>
                    <DoctCol xs={12} sm={6} md={12}>
                        <>
                            {filtersListArray?.slice(perRowItems, totalFilters).map((item: any, i: number) => (
                                <InfiniteScrollProvider
                                    sourceData={item.array}
                                    key={`scroller-${item?.array.length}-${i}`}
                                >
                                    <CollapsibleWrapper
                                        item={item}
                                        key={`collapse-wrapper-${item?.array.length}-${i}`}
                                    />
                                </InfiniteScrollProvider>
                            ))}
                        </>
                    </DoctCol>
                </DoctRow>
            </>
        );
    },
);

export default FiltersList;
