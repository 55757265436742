import React from 'react';
import { DoctRow, DoctCol } from '@doct-react/core';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useWindowSize } from '../../Hooks/useWndowSize.js';

import '../Skeleton.scss';

interface FilterCardProps {}

const SkeletonAllJobCard: React.FC<FilterCardProps> = () => {
    const size = useWindowSize();
    const { width } = size[0];

    return (
        <>
            {width >= 768 ? (
                <div className="col-auto border-radius all_jobs_card px-2 border-radius py-4 bg-grey-200">
                    <Stack spacing={1}>
                        <DoctRow>
                            <Skeleton
                                className="mt-2 ml-4"
                                variant="rectangular"
                                animation="wave"
                                width={84}
                                height={84}
                            />

                            <DoctCol sm={10}>
                                <>
                                    <Skeleton
                                        className="mt-2 ml-2"
                                        variant="text"
                                        animation="wave"
                                        width={248}
                                        height={18}
                                    />
                                    <Skeleton
                                        className="mt-1 ml-2"
                                        variant="text"
                                        width={331}
                                        height={20}
                                        animation="wave"
                                    />
                                    <Skeleton
                                        className="mt-1 ml-2"
                                        variant="text"
                                        animation="wave"
                                        width={248}
                                        height={18}
                                    />
                                    <Skeleton
                                        className="mt-1 ml-2"
                                        variant="text"
                                        animation="wave"
                                        width={140}
                                        height={18}
                                    />
                                </>
                            </DoctCol>

                            <div className="d-flex mt-4 px-2">
                                <Skeleton
                                    className="mt-1 ml-2"
                                    variant="text"
                                    width={150}
                                    height={18}
                                    animation="wave"
                                />
                                <Skeleton
                                    className="mt-1 ml-2"
                                    variant="text"
                                    width={80}
                                    height={18}
                                    animation="wave"
                                />
                            </div>
                        </DoctRow>
                    </Stack>
                </div>
            ) : (
                <Stack spacing={1} className="border-radius bg-grey-200 col-md-9">
                    <div className="d-flex">
                        <Skeleton className="mt-2 ml-2" variant="rectangular" animation="wave" width={34} height={34} />
                        <Skeleton className="mt-3 ml-2" variant="text" animation="wave" width={248} height={18} />
                    </div>
                    <DoctRow>
                        <DoctCol sm={10}>
                            <>
                                <Skeleton className="mt-1" variant="text" width={310} height={20} animation="wave" />
                                <Skeleton className="mt-1" variant="text" animation="wave" width={248} height={18} />
                                <Skeleton
                                    className="mt-1 mb-5"
                                    variant="text"
                                    animation="wave"
                                    width={140}
                                    height={18}
                                />
                            </>
                        </DoctCol>
                    </DoctRow>
                </Stack>
            )}
        </>
    );
};

export default SkeletonAllJobCard;
