import React, { ReactElement, Component, ReactNode } from 'react';

import { DoctContainer, DoctIcon } from '@doct-react/core';

import './DoctBreadcrumbs.scss';

interface Props {
    children: any;
    className?: string;
}

function BreadCrumbsItem({ children }: Props): ReactElement {
    return children;
}

class DoctBreadcrumbs extends Component<Props> {
    static Item = BreadCrumbsItem;

    render(): JSX.Element {
        const { children = [], className } = this.props;

        const items = children.length > 0 ? [...children] : [children];
        return (
            <div className={`doct-breadcrumbs${className ? ` ${className}` : ''}`}>
                <DoctContainer>
                    <ul>
                        {items.map((item, i) => (
                            <li
                                className={`doct-breadcrumbs-item${
                                    item.props.className ? ` ${item.props.className}` : ''
                                }`}
                                key={i}
                            >
                                {item}
                                <DoctIcon name="right" className="doct-breadcrumbs-icon" width="14" />
                            </li>
                        ))}
                    </ul>
                </DoctContainer>
            </div>
        );
    }
}

export default DoctBreadcrumbs;
