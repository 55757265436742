// /* eslint-disable no-use-before-define */
// import React from 'react';
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/lab/Autocomplete';
// import InputAdornment from '@mui/material/InputAdornment';
// import { DoctIcon } from '@doct-react/core';

// interface SearchInputProps {
//     name: string;
//     placeholder?: string;
//     onChangeHandler: (e, val) => void;
//     onInputChangeHandler?: (e, val) => void;
//     options: { title: string; value?: string }[];
//     value?: string;
//     inputValue?: string;
//     defaultValue?: string;
// }

// const SearchInput: React.FC<SearchInputProps> = ({
//     name,
//     options = [],
//     placeholder,
//     onChangeHandler,
//     value,
//     onInputChangeHandler,
//     inputValue,
// }) => {
//     return (
//         <div className="doct-input search-input">
//             <Autocomplete
//                 freeSolo
//                 inputValue={inputValue}
//                 value={value}
//                 id={name}
//                 onInputChange={(e, value) => onInputChangeHandler(e, value)}
//                 onChange={(e, value) => onChangeHandler(e, value)}
//                 options={options.map((option) => option.title)}
//                 renderInput={(params) => (
//                     <TextField
//                         {...params}
//                         placeholder={placeholder}
//                         margin="normal"
//                         InputProps={{
//                             ...params.InputProps,
//                             type: 'search',
//                             startAdornment: (
//                                 <InputAdornment position="start">
//                                     <DoctIcon name="search" className="text-grey-600" width="24" height="24" />
//                                 </InputAdornment>
//                             ),
//                         }}
//                     />
//                 )}
//             />
//         </div>
//     );
// };

// export default SearchInput;

import React, { useState } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Controller, Control, FieldError } from 'react-hook-form';
import { DoctIcon } from '@doct-react/core';
import { DeepMap } from 'react-hook-form/dist/types/utils';

interface SearchInputProps {
    label?: string;
    id: string;
    name: string;
    control: Control<Record<string, any>>;
    variant?: 'standard' | 'filled' | 'outlined';
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    isErrors?: DeepMap<Record<string, any>, FieldError>;
    validationRules?: any;
    defaultValue?: any;
    placeholder?: string;
    inverseBackground?: boolean;
    hideSearchIcon?: boolean;
}

const SearchInput: React.FC<SearchInputProps> = ({
    label,
    id,
    name,
    variant = 'standard',
    disabled = false,
    readOnly = false,
    className,
    control,
    isErrors,
    defaultValue = '',
    validationRules,
    placeholder,
    inverseBackground = false,
    hideSearchIcon,
    ...rest
}) => {
    // const [searchTerm, setSearchTerm] = useState(searchValue);

    return (
        <>
            <Controller
                // touched={touched[name]}
                rules={validationRules}
                render={({ onChange, value }) => (
                    <TextField
                        className={`doct-input search-input${value ? ' doct-input-focused' : ''}${
                            readOnly ? ' doct-read-only-input' : ''
                        }${className ? ` ${className}` : ''}${disabled ? ' disabled' : ''} ${
                            inverseBackground ? 'search-input-grey' : ''
                        }`}
                        id={name}
                        name={name}
                        value={value}
                        onChange={(e) => {
                            if (!!!value && e.target.value == ' ') {
                                return;
                            }
                            onChange(e);
                        }}
                        label={label}
                        placeholder={placeholder}
                        InputProps={{
                            startAdornment: hideSearchIcon ? null : (
                                <InputAdornment position="start">
                                    <DoctIcon width="24" height="24" name="search" className="text-grey-600" />
                                </InputAdornment>
                            ),
                        }}
                        variant={variant}
                    />
                )}
                defaultValue={defaultValue}
                name={name}
                id={id}
                control={control}
                {...rest}
            />
        </>
    );
};

export default SearchInput;
