import React, { useState, useEffect } from 'react';
import DoctModal from '../../Modal/DoctModal';
import { DoctTypography } from '@doct-react/core';
import '../Static.scss';

interface TermsConditionProps {
    handleClose?: () => void;
    open?: boolean;
}
const TermsCondition: React.FC<TermsConditionProps> = ({ handleClose, open }) => {
    const [doctUrls, setDoctUrls] = useState(null);

    useEffect(() => {
        setDoctUrls(typeof window !== 'undefined' && window.DoctUrls ? window.DoctUrls : null);
    }, []);

    return (
        <DoctModal
            className="privacy-policy-modal static-modal"
            title="Terms and Conditions "
            width={572}
            showFooter={false}
            open={open}
            handleClose={handleClose}
        >
            <DoctTypography variant="body2" className="text-grey-600">
                Docthub – Enterprise Solutions
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                (<a href={`${doctUrls?.doct_dashboard_url}`}> https://enterprise.docthub.com/</a>) <br />
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                Our Terms and Conditions were last updated on 15 March 2022.
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                Please read these terms and conditions carefully before using Our Enterprise Platform Services.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                BACKGROUND
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                Docthub.com is a digital platform for healthcare professionals, offering healthcare career and
                professional upgrade website as well as Android and iOS Mobile app for Users. It aggregates healthcare
                jobs, courses, events, masterclass, certification programs offered by different partnered organizations.
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                Docthub – Enterprise Solutions is for business users to post their content and get user responses on
                related Docthub’s user platform. It also includes features and tools to grow and manage their
                professional work.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                INTERPRETATION
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear in
                singular or in plural.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                DEFINITIONS
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                For the purposes of these Terms and Conditions:
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    <b> “Affiliate” </b>means an entity that controls, is controlled by or is under common control with
                    a party, where "control" means ownership of the shares, equity interest or other securities entitled
                    to vote for election of directors or other managing authority.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b> “Account” </b> means a unique account created for You to access our Service or parts of
                    ourService.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b> “Company” </b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
                    refers to DOCTHUB HEALTH TECH PRIVATE LIMITED.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Country”</b> refers to INDIA.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Content”</b>refers to content such as text, images, videos or other information that can be
                    posted, uploaded, linked to or otherwise made available by You, regardless of the form of that
                    content.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Device”</b>means any device that can access the Service such as a computer, a cellphone or a
                    digital tablet.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Feedback” </b> means feedback, innovations or suggestions sent by You regarding the attributes,
                    performance or features of our Service.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Products”</b> refer to the products or items offered for sale on the Service.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Orders”</b>mean a request by You to purchase Products from Us.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Promotions”</b>refer to contests, sweepstakes or other promotions offered through the Service.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Service” </b> refers to the Website{' '}
                    <a href={`${doctUrls?.doct_dashboard_url}`}> https://enterprise.docthub.com/</a>{' '}
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Product”</b>refers to subscriptions, tool, cloud solutions, professional service, web
                    applications, material, items, or any other Goods and Services offered by or listed on Service.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Terms and Conditions”</b> (also referred as <b> "Terms" </b>) mean these Terms and Conditions
                    that form the entire agreement between You and the Company regarding the use of the Service. .
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Third-party Social Media Service” </b> means any services or content (including data,
                    information, products or services) provided by a third-party that may be displayed, included or made
                    available by the Service.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“Website”</b> refers to [DOCTHUB], accessible from{' '}
                    <a href={`${doctUrls?.doct_dashboard_url}`}> https://enterprise.docthub.com/</a>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>“You” </b> means the individual accessing or using the Service, or the company, or other legal
                    entity on behalf of which such individual is accessing or using the Service, as applicable.
                </DoctTypography>
            </ul>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Acknowledgment
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                These are the Terms and Conditions governing the use of this Service and the agreement that operates
                between You and the Company. These Terms and Conditions set out the rights and obligations of all users
                regarding the use of the Service. Your access to and use of the Service is conditioned on Your
                acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all
                visitors, users and others who access or use the Service. By accessing or using the Service You agree to
                be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then
                You may not access the Service. You represent that you are over the age of 18. The Company does not
                permit those under 18 to use the Service. Your access to and use of the Service is also conditioned on
                Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes
                Our policies and procedures on the collection, use and disclosure of Your personal information when You
                use the Service or the Website or Mobile App and tells You about Your privacy rights and how the law
                protects You. Please read Our Privacy Policy carefully before using Our Service.
            </DoctTypography>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                User Accounts
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                When You create an account with Us, You must provide Us information that is accurate, complete, and
                current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate
                termination of Your account on Our Service. You are responsible for safeguarding the password that You
                use to access the Service and for any activities or actions under Your password, whether Your password
                is with Our Service or a Third-Party Social Media Service. You agree not to disclose Your password to
                any third party. You must notify Us immediately upon becoming aware of any breach of security or
                unauthorized use of Your account. You may not use as a username the name of another person or entity or
                that is not lawfully available for use, a name or trademark that is subject to any rights of another
                person or entity other than You without appropriate authorization, or a name that is otherwise
                offensive, vulgar or obscene.
            </DoctTypography>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Content
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <u>Your Right to Post Content </u>
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                Our Service allows You to post Content. You are responsible for the Content that You post to the
                Service, including its legality, reliability, and appropriateness. By posting Content to the Service,
                You grant Us the right and license to use, modify, publicly perform, publicly display, reproduce, and
                distribute such Content on and through the Service. You retain any and all of Your rights to any Content
                You submit, post or display on or through the Service and You are responsible for protecting those
                rights. You agree that this license includes the right for Us to make Your Content available to other
                users of the Service, who may also use Your Content subject to these Terms. You represent and warrant
                that: (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights and
                license as provided in these Terms, and (ii) the posting of Your Content on or through the Service does
                not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any
                person.
            </DoctTypography>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Content Restrictions
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                The Company is not responsible for the content of the Service's users. You expressly understand and
                agree that You are solely responsible for the Content and for all activity that occurs under your
                account, whether done so by You or any third person using Your account. You may not transmit any Content
                that is unlawful, offensive, upsetting, intended to disgust, threatening, libelous, defamatory, obscene
                or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the
                following:
            </DoctTypography>

            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    Unlawful or promoting unlawful activity.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    Defamatory, discriminatory, or mean-spirited content, including references or commentary about
                    religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain
                    letters, any other form of unauthorized solicitation, or any form of lottery or gambling.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    Containing or installing any viruses, worms, malware, trojan horses, or other content that is
                    designed or intended to disrupt, damage, or limit the functioning of any software, hardware or
                    telecommunications equipment or to damage or obtain unauthorized access to any data or other
                    information of a third person.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    Infringing on any proprietary rights of any party, including patent, trademark, trade secret,
                    copyright, right of publicity or other rights.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    Impersonating any person or entity including the Company and its employees or representatives.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    Violating the privacy of any third person.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    False information and features.
                </DoctTypography>
            </ul>
            <DoctTypography variant="body2" className="text-grey-600">
                The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or
                not any Content is appropriate and complies with this Terms, refuse or remove this Content. The Company
                further reserves the right to make formatting and edits and change the manner of any Content. The
                Company can also limit or revoke the use of the Service if You post such objectionable Content. As the
                Company cannot control all content posted by users and/or third parties on the Service, you agree to use
                the Service at your own risk. You understand that by using the Service You may be exposed to content
                that You may find offensive, indecent, incorrect or objectionable, and You agree that under no
                circumstances will the Company be liable in any way for any content, including any errors or omissions
                in any content, or any loss or damage of any kind incurred as a result of your use of any content.
            </DoctTypography>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Content Backups
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                Although regular backups of Content are performed, the Company does not guarantee there will be no loss
                or corruption of data. Corrupt or invalid backup points may be caused by, without limitation, Content
                that is corrupted prior to being backed up or that changes during the time a backup is performed. The
                Company will provide support and attempt to troubleshoot any known or discovered issues that may affect
                the backups of Content. But You acknowledge that the Company has no liability related to the integrity
                of Content or the failure to successfully restore Content to a usable state. You agree to maintain a
                complete and accurate copy of any Content in a location independent of the Service.
            </DoctTypography>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Copyright Policy
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <u>Intellectual Property Infringement </u>
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                We respect the intellectual property rights of others. It is Our policy to respond to any claim that
                Content posted on the Service infringes a copyright or other intellectual property infringement of any
                person. If You are a copyright owner, or authorized on behalf of one, and You believe that the
                copyrighted work has been copied in a way that constitutes copyright infringement that is taking place
                through the Service, You must submit Your notice in writing to the attention of our copyright agent via{' '}
                <a href="mailto:office@docthub.com">office@docthub.com </a>
                and include in Your notice a detailed description of the alleged infringement. You may be held
                accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is
                infringing Your copyright.
            </DoctTypography>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Copyright Infringement Claims
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                You may submit a notification pursuant to the Copyright Act (India) by providing your Copyright Agent
                with the following information in writing; special or similar damages, even if advised of the
                possibility of such damages.
            </DoctTypography>

            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    An electronic or physical signature of the person authorized to act on behalf of the owner of the
                    copyright's interest.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    A description of the copyrighted work that You claim has been infringed, including the URL (i.e.,
                    web page address) of the location where the copyrighted work exists or a copy of the copyrighted
                    work.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    Identification of the URL or other specific location on the Service where the material that You
                    claim is infringing is located.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    Your address, telephone number, and email address.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    A statement by You that You have a good faith belief that the disputed use is not authorized by the
                    copyright owner, its agent, or the law.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    A statement by You, made under penalty of perjury, that the above information in Your notice is
                    accurate and that You are the copyright owner or authorized to act on the copyright owner's behalf.
                </DoctTypography>
            </ul>
            <DoctTypography variant="body2" className="text-grey-600 ">
                You can contact our copyright agent via email
                <a href="mailto:office@docthub.com"> office@docthub.com </a> Upon receipt of a notification, the Company
                will take whatever action, in its sole discretion, it deems appropriate, including removal of the
                challenged content from the Service.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Placing Orders for Products
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600 ">
                By placing an Order for Products through the Service, You warrant that You are legally capable of
                entering into binding contracts.
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                <u>Your Information</u>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                If You wish to place an Order for Products available on the Service, You may be asked to supply certain
                information relevant to Your Order including, without limitation, Your name, Your email, Your phone
                number, Your credit card number, the expiration date of Your credit card, Your billing address, Taxation
                ID and Your shipping information. You represent and warrant that: (i) You have the legal right to use
                any credit or debit card(s) or other payment method(s) in connection with any Order; and that (ii) the
                information You supply to us is true, correct and complete. By submitting such information, You grant us
                the right to provide the information to payment processing third parties for purposes of facilitating
                the completion of Your Order.
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                <u>Order Cancellation </u>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                We reserve the right to refuse or cancel Your Order at any time for certain reasons including but not
                limited to:
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    Products availability
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    Errors in the description or prices for Products
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    Errors in Your Order
                </DoctTypography>
            </ul>
            <DoctTypography variant="list" className="text-grey-600">
                We reserve the right to refuse or cancel Your Order if fraud or an unauthorized or illegal transaction
                is suspected.
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                <u>Your Order Cancellation Rights </u>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                Any Products you purchase can only be returned or refunded in accordance with these Terms and
                Conditions.
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                <u>Availability, Errors and Inaccuracies </u>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                We are constantly updating Our offerings of Products on the Service. The Products available on Our
                Service may be mispriced, described inaccurately, or unavailable, and We may experience delays in
                updating information regarding our Products on the Service and in Our advertising on other websites. We
                cannot and do not guarantee the accuracy or completeness of any information, including prices, product
                images, specifications, availability, and services. We reserve the right to change or update information
                and to correct errors, inaccuracies, or omissions at any time without prior notice.
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                <u>Prices Policy </u>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                The Company reserves the right to revise its prices at any time prior to accepting an Order. The prices
                quoted may be revised by the Company subsequent to accepting an Order in the event of any occurrence
                affecting delivery caused by government action, variation in customs duties, increased shipping charges,
                higher foreign exchange costs and any other matter beyond the control of the Company. In that event, You
                will have the right to cancel Your Order.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600 ">
                Payments
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                Payment can be made through various payment methods we have available, such as Visa, MasterCard, Rupay,
                Affinity Card, American Express cards or online payment methods (for example, PayPal, Stripe, Razorpay,
                CashFree, Kotak Payment Gateway) or Digital Wallets (for example, PayTM, Amazon Pay, Google Pay, UPI,
                BharatPe, PhonePay) Payment cards (credit cards or debit cards) are subject to validation checks and
                authorization by Your card issuer. If we do not receive the required authorization, We will not be
                liable for any delay or non-delivery of Your Order.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600 ">
                Promotions
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                Any Promotions made available through the Service may be governed by rules that are separate from these
                Terms. If You participate in any Promotions, please review the applicable rules as well as our Privacy
                policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600 ">
                Intellectual Property
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                The Service and its original content (excluding Content provided by You or other users), features and
                functionality are and will remain the exclusive property of the Company and its licensors. The Service
                is protected by copyright, trademark, and other laws of both the Country and foreign countries. Our
                trademarks and trade dress may not be used in connection with any product or service without the prior
                written consent of the Company.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600 ">
                Your Feedback to Us
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                You assign all rights, title and interest in any Feedback You provide the Company. If for any reason
                such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable,
                royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify
                and exploit such Feedback without restriction.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600 ">
                Links to Other Websites
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                Our Service may contain links to third-party web sites or services that are not owned or controlled by
                the Company. The Company has no control over, and assumes no responsibility for, the content, privacy
                policies, or practices of any third party web sites or services. You further acknowledge and agree that
                the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or
                alleged to be caused by or in connection with the use of or reliance on any such content, goods or
                services available on or through any such web sites or services. We strongly advise You to read the
                terms and conditions and privacy policies of any third-party web sites or services that You visit.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600 ">
                Termination
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason
                whatsoever, including without limitation if You breach these Terms and Conditions. Upon termination,
                Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may
                simply discontinue using the Service.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600 ">
                Limitation of Liability
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                Notwithstanding any damages that You might incur, the entire liability of the Company and any of its
                suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be
                limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased
                anything through the Service. To the maximum extent permitted by applicable law, in no event shall the
                Company or its suppliers be liable for any special, incidental, indirect, or consequential damages
                whatsoever (including, but not limited to, damages for loss of profits, loss of data or other
                information, for business interruption, for personal injury, loss of privacy arising out of or in any
                way related to the use of or inability to use the Service, third-party software and/or third-party
                hardware used with the Service, or otherwise in connection with any provision of this Terms), even if
                the Company or any supplier has been advised of the possibility of such damages and even if the remedy
                fails of its essential purpose. Some states do not allow the exclusion of implied warranties or
                limitation of liability for incidental or consequential damages, which means that some of the above
                limitations may not apply. In these states, each party's liability will be limited to the greatest
                extent permitted by law.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600 ">
                "AS IS" and "AS AVAILABLE" Disclaimer
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                Without limiting the foregoing, neither the Company nor any of the company's provider makes any
                representation or warranty of any kind, express or implied: (i) as to the operation or availability of
                the Service, or the information, content, and materials or products included thereon; (ii) that the
                Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any
                information or content provided through the Service; or (iv) that the Service, its servers, the content,
                or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms,
                malware, timebombs or other harmful components.
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on
                applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may
                not apply to You. But in such a case the exclusions and limitations set forth in this section shall be
                applied to the greatest extent enforceable under applicable law.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600 ">
                Governing Law
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of
                the Service. Your use of the Product may also be subject to other local, state, national, or
                international laws.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600 ">
                Disputes Resolution
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                If You have any concern or dispute about the Service, You agree to first try to resolve the dispute
                informally by contacting the Company.
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                For Indian Users
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                If you are a consumer from India, we would like to inform that the Company has proactively adopted The
                Data Protection Bill 2021 (which was proposed in Indian Parliament on December 2021). However, the
                Company will update the policies whenever such law will be implemented.
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                For European Union (EU) Users
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the
                country in which you are resident in.
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                United States Legal Compliance
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                You represent and warrant that (i) You are not located in a country that is subject to the United States
                government embargo, or that has been designated by the United States government as a "terrorist
                supporting" country, and (ii) You are not listed on any United States government list of prohibited or
                restricted parties.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600 ">
                Severability and Waiver
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                Severability
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed
                and interpreted to accomplish the objectives of such provision to the greatest extent possible under
                applicable law and the remaining provisions will continue in full force and effect.
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                Waiver
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                Except as provided herein, the failure to exercise a right or to require performance of an obligation
                under this Terms shall not effect a party's ability to exercise such right or require such performance
                at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600 ">
                Changes to These Terms and Conditions
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600 ">
                We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a
                revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any
                new terms taking effect. What constitutes a material change will be determined at Our sole discretion.
                By continuing to access or use Our Service after those revisions become effective, You agree to be bound
                by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the
                website and the Service.
            </DoctTypography>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Contact Us
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600 ">
                If you have any questions about this Privacy Policy, You can contact us:
            </DoctTypography>

            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    By visiting this page on our website:
                    <a href={`${doctUrls?.doct_brand_url}/contact-us`}>https://www.docthub.com/contact-us</a>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    By sending us an email:<a href="mailto:enterprise@docthub.com">enterprise@docthub.com </a>
                </DoctTypography>
            </ul>
        </DoctModal>
    );
};

export default TermsCondition;
