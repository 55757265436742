import React, { useState, useEffect } from 'react';
import DoctModal from '../../Modal/DoctModal';
import { DoctTypography } from '@doct-react/core';
import '../Static.scss';

interface PrivacyPolicyProps {
    handleClose: () => void;
    open: boolean;
}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({ handleClose, open }) => {
    const [doctUrls, setDoctUrls] = useState(null);

    useEffect(() => {
        setDoctUrls(typeof window !== 'undefined' && window.DoctUrls ? window.DoctUrls : null);
    }, []);

    return (
        <DoctModal
            className="privacy-policy-modal static-modal"
            title="Privacy Policy"
            width={572}
            showFooter={false}
            open={open}
            handleClose={handleClose}
        >
            <DoctTypography variant="body2" className="text-grey-600">
                <p>
                    Our Privacy Policy was last updated on 20 May 2022. This Privacy Policy describes Our policies and
                    procedures on the collection, use and disclosure of Your information when You use the Service and
                    tells You about Your privacy rights and how the law protects You. We use Your Personal data to
                    provide and improve the Service. By using the Service, You agree to the collection and use of
                    information in accordance with this Privacy Policy.
                </p>
            </DoctTypography>
            <DoctTypography variant="body2" className=" font-weight-bold text-grey-600">
                Interpretation and Definitions
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <u>Interpretation </u>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <p>
                    The words of which the initial letter is capitalized have meanings defined under the following
                    conditions. The following definitions shall have the same meaning regardless of whether they appear
                    in singular or in plural.
                </p>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <u>Definitions </u>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <p>For the purposes of this Privacy Policy:</p>
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    <b>"Account"</b> means a unique account created for You to access our Service or parts of our
                    Service.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b> "Company"</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
                    refers to Docthub Health Tech Private Limited. For the purpose of the GDPR, the Company is the Data
                    Controller.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b>"Country"</b> refers to INDIA.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b> "Cookies"</b> are small files that are placed on Your computer, mobile device or any other
                    device by a website, containing the details of Your browsing history on that website among its many
                    uses.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b>"Data Controller"</b>, for the purposes of the GDPR (General Data Protection Regulation), refers
                    to the Company as the legal person which alone or jointly with others determines the purposes and
                    means of the processing of Personal Data.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b> "Device"</b> means any device that can access the Service such as a computer, a cell phone or a
                    digital tablet.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b> "Personal Data"</b> is any information that relates to an identified or identifiable individual.
                    For the purposes of GDPR, Personal Data means any information relating to You such as a name, an
                    identification number, location data, online identifier or to one or more factors specific to the
                    physical, physiological, genetic, mental, economic, cultural or social identity.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b> "Service"</b> refers to the Website.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b>"Service Provider"</b> means any natural or legal person who processes the data on behalf of the
                    Company. It refers to third-party companies or individuals employed by the Company to facilitate the
                    Service, to provide the Service on behalf of the Company, to perform services related to the Service
                    or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service
                    Providers are considered Data Processors.
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b>"Usage Data"</b> refers to data collected automatically, either generated by the use of the
                    Service or from the Service infrastructure itself (for example, the duration of a page visit).
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <b>"Website"</b> refers to DOCTHUB, accessible from
                    <a href={`${doctUrls?.doct_brand_url}`}> www.docthub.com</a>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>"You"</b> means the individual accessing or using the Service, or the company, or other legal
                    entity on behalf of which such individual is accessing or using the Service, as applicable. Under
                    GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User
                    as you are the individual using the Service.
                </DoctTypography>
            </ul>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Collecting and Using Your Personal Data
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <p>Types of Data Collected </p>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <u>Personal Data</u>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <p>
                    While using Our Service, We may ask You to provide Us with certain personally identifiable
                    information that can be used to contact or identify You. Personally identifiable information may
                    include, but is not limited to:
                </p>
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    Email address
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    First name and last name
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    Phone number
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    Address, State, Province, ZIP/Postal code, City
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    Usage Data
                </DoctTypography>
            </ul>
            <DoctTypography variant="body2" className="text-grey-600">
                <u>Usage Data </u>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <p> Usage Data is collected automatically when using the Service. </p>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <p>
                    Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP
                    address), browser type, browser version, the pages of our Service that You visit, the time and date
                    of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                    When You access the Service by or through a mobile device, We may collect certain information
                    automatically, including, but not limited to, the type of mobile device You use, Your mobile device
                    unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile
                    Internet browser You use, unique device identifiers and other diagnostic data. We may also collect
                    information that Your browser sends whenever You visit our Service or when You access the Service by
                    or through a mobile device.
                </p>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <u>Tracking Technologies and Cookies</u>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <p>
                    We use Cookies and similar tracking technologies to track the activity on Our Service and store
                    certain information. Tracking technologies used are beacons, tags, and scripts to collect and track
                    information and to improve and analyze Our Service. The technologies We use may include:
                </p>
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    <b> Cookies or Browser Cookies.</b> A cookie is a small file placed on Your Device. You can instruct
                    Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do
                    not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted
                    Your browser setting so that it will refuse Cookies, our Service may use Cookies.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b> Web Beacons.</b> Certain sections of our Service and our emails may contain small electronic
                    files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that
                    permit the Company, for example, to count users who have visited those pages or opened an email and
                    for other related website statistics (for example, recording the popularity of a certain section and
                    verifying system and server integrity).
                </DoctTypography>
            </ul>
            <DoctTypography variant="body2" className="text-grey-600">
                <p>
                    Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal
                    computer or mobile device when You go offline, while Session Cookies are deleted as soon as You
                    close Your web browser.
                </p>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <p>We use both Session and Persistent Cookies for the purposes set out below: </p>
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    <p>
                        <b> Necessary / Essential Cookies Type:</b> Session Cookies Administered by Us for the user
                        experience purpose. These Cookies are essential to provide You with services available through
                        the Website and to enable You to use some of its features. They help to authenticate users and
                        prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked
                        for cannot be provided, and We only use these Cookies to provide You with those services.
                    </p>
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <p>
                        <b> Cookies Policy / Notice Acceptance Cookies Type:</b> Persistent Cookies Administered by Us
                        for the transparency and consent purpose. These Cookies identify if users have accepted the use
                        of cookies on the Website.
                    </p>
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <p>
                        <b>Functionality Cookies Type:</b> Persistent Cookies Administered by Us for website features
                        analysis and enhancement purpose. These Cookies allow us to remember choices You make when You
                        use the Website, such as remembering your login details or language preference. The purpose of
                        these Cookies is to provide You with a more personal experience and to avoid You having to
                        re-enter your preferences every time You use the Website.
                    </p>
                </DoctTypography>

                <DoctTypography variant="list" className="text-grey-600">
                    <p>
                        <b> Tracking and Performance Cookies Type:</b> Persistent Cookies Administered by Third-Parties
                        to analyze user traffic purpose. These Cookies are used to track information about traffic to
                        the Website and how users use the Website. The information gathered via these Cookies may
                        directly or indirectly identify you as an individual visitor. This is because the information
                        collected is typically linked to a pseudonymous identifier associated with the device you use to
                        access the Website. We may also use these Cookies to test new pages, features or new
                        functionality of the Website to see how our users react to them.
                    </p>
                </DoctTypography>
            </ul>
            <DoctTypography variant="body2" className="text-grey-600">
                <p>
                    For more information about the cookies we use and your choices regarding cookies, please visit our
                    Cookies Policy or the Cookies section of our Privacy Policy.
                </p>
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Use of Your Personal Data
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <p>The Company may use Personal Data for the following purposes:</p>
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    <p>
                        <b>To provide and maintain our Service, </b>including to monitor the usage of our Service.
                    </p>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <p>
                        <b> To manage Your Account:</b> to manage Your registration as a user of the Service. The
                        Personal Data You provide can give You access to different functionalities of the Service that
                        are available to You as a registered user.{' '}
                    </p>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <p>
                        <b> For the performance of a contract:</b> the development, compliance and undertaking of the
                        purchase contract for the products, items or services You have purchased or of any other
                        contract with Us through the Service.
                    </p>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <p>
                        <b> To contact You:</b> To contact You by email, telephone calls, SMS, or other equivalent forms
                        of electronic communication, such as a mobile application's push notifications regarding updates
                        or informative communications related to the functionalities, products or contracted services,
                        including the security updates, when necessary or reasonable for their implementation.{' '}
                    </p>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b> To provide You with news,</b> special offers and general information about other goods, services
                    and events which we offer that are similar to those that you have already purchased or enquired
                    about unless You have opted not to receive such information.{' '}
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    {' '}
                    <b>To manage Your requests:</b> To attend and manage Your requests to Us.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>For business transfers:</b> We may use Your information to evaluate or conduct a merger,
                    divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of
                    Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding,
                    in which Personal Data held by Us about our Service users is among the assets transferred.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b> For other purposes:</b> We may use Your information for other purposes, such as data analysis,
                    identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate
                    and improve our Service, products, services, marketing and your experience.
                </DoctTypography>
            </ul>
            <DoctTypography variant="body2" className="text-grey-600">
                We may share Your personal information in the following situations:
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    <b> With Service Providers:</b> We may share Your personal information with Service Providers to
                    monitor and analyze the use of our Service, for payment processing, to contact You.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>For business transfers:</b> We may share or transfer Your personal information in connection
                    with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of
                    all or a portion of Our business to another company.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>With Affiliates:</b> We may share Your information with Our affiliates, in which case we will
                    require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any
                    other subsidiaries, joint venture partners or other companies that We control or that are under
                    common control with Us.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>With business partners:</b> We may share Your information with Our business partners to offer You
                    certain products, services or promotions.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b> With other users:</b> when You share personal information or otherwise interact in the public
                    areas with other users, such information may be viewed by all users and may be publicly distributed
                    outside.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b> With Your consent:</b> We may disclose Your personal information for any other purpose with Your
                    consent.
                </DoctTypography>
            </ul>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                <b>Retention of Your Personal Data </b>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
                this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with
                our legal obligations (for example, if we are required to retain your data to comply with applicable
                laws), resolve disputes, and enforce our legal agreements and policies. The Company will also retain
                Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of
                time, except when this data is used to strengthen the security or to improve the functionality of Our
                Service, or We are legally obligated to retain this data for longer time periods.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Transfer of Your Personal Data
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                Your information, including Personal Data, is processed at the Company's operating offices and in any
                other places where the parties involved in the processing are located. It means that this information
                may be transferred to — and maintained on — computers located outside of Your state, province, country
                or other governmental jurisdiction where the data protection laws may differ than those from Your
                jurisdiction. Your consent to this Privacy Policy followed by Your submission of such information
                represents Your agreement to that transfer. The Company will take all steps reasonably necessary to
                ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of
                Your Personal Data will take place to an organization or a country unless there are adequate controls in
                place including the security of Your data and other personal information.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Disclosure of Your Personal Data
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <u>Business Transactions </u>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
                transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a
                different Privacy Policy.
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <u>Law enforcement </u>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                Under certain circumstances, the Company may be required to disclose Your Personal Data if required to
                do so by law or in response to valid requests by public authorities (e.g. a court or a government
                agency).
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <u>Other legal requirements</u>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    Comply with a legal obligation
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    Protect and defend the rights or property of the Company
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    Prevent or investigate possible wrongdoing in connection with the Service
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    Protect the personal safety of Users of the Service or the public
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    Protect against legal liability
                </DoctTypography>
            </ul>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Security of Your Personal Data
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                The security of Your Personal Data is important to Us, but remember that no method of transmission over
                the Internet, or method of electronic storage is 100% secure. While We strive to use commercially
                acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Detailed Information on the Processing of Your Personal Data
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                The Service Providers that We use, may have access to Your Personal Data. These third-party vendors
                collect, store, use, process and transfer information about Your activity on Our Service in accordance
                with their Privacy Policies.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Analytics
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                We may use third-party Service providers to monitor and analyze the use of our Service.
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    Google Analytics
                </DoctTypography>
            </ul>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Email Marketing
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and
                other information that may be of interest to You. You may opt-out of receiving any, or all, of these
                communications from Us by following the unsubscribe link or instructions provided in any email We send
                or by contacting Us.{' '}
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Payments
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                We may provide paid products and/or services within the Service. In that case, we may use third-party
                services for payment processing. We will not store or collect Your payment card details. That
                information is provided directly to Our third-party payment processors whose use of Your personal
                information is governed by their Privacy Policy. These payment processors adhere to the standards set by
                Reserve Bank of India (RBI) as managed by Ministry of Finance, Government of India. RBI requirements
                helps to ensure the secure handling of payment information.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Legal Basis for Processing Personal Data
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                We may process Personal Data under the following conditions:
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    <b> Consent:</b> You have given Your consent for processing Personal Data for one or more specific
                    purposes.{' '}
                </DoctTypography>{' '}
                <DoctTypography variant="list" className="text-grey-600">
                    <b> Performance of a contract:</b> Provision of Personal Data is necessary for the performance of an
                    agreement with You and/or for any pre-contractual obligations thereof.
                </DoctTypography>{' '}
                <DoctTypography variant="list" className="text-grey-600">
                    <b>Legal obligations:</b> Processing Personal Data is necessary for compliance with a legal
                    obligation to which the Company is subject.
                </DoctTypography>{' '}
                <DoctTypography variant="list" className="text-grey-600">
                    <b> Vital interests:</b> Processing Personal Data is necessary in order to protect Your vital
                    interests or of another natural person.
                </DoctTypography>{' '}
                <DoctTypography variant="list" className="text-grey-600">
                    <b>Public interests:</b> Processing Personal Data is related to a task that is carried out in the
                    public interest or in the exercise of official authority vested in the Company.
                </DoctTypography>{' '}
                <DoctTypography variant="list" className="text-grey-600">
                    <b>Legitimate interests:</b> Processing Personal Data is necessary for the purposes of the
                    legitimate interests pursued by the Company.
                </DoctTypography>{' '}
            </ul>
            <DoctTypography variant="body2" className="text-grey-600">
                In any case, the Company will gladly help to clarify the specific legal basis that applies to the
                processing, and in particular whether the provision of Personal Data is a statutory or contractual
                requirement, or a requirement necessary to enter into a contract.{' '}
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Your Rights
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can
                exercise Your rights.{' '}
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                You have the right under this Privacy Policy, and by law of the country you belong to:
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    <b> Request access to Your Personal Data.</b> The right to access, update or delete the information
                    We have on You. Whenever made possible, you can access, update or request deletion of Your Personal
                    Data directly within Your account settings section. If you are unable to perform these actions
                    yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal
                    Data We hold about You.
                </DoctTypography>{' '}
                <DoctTypography variant="list" className="text-grey-600">
                    <b>Request correction of the Personal Data that We hold about You.</b> You have the right to have
                    any incomplete or inaccurate information We hold about You corrected.
                </DoctTypography>{' '}
                <DoctTypography variant="list" className="text-grey-600">
                    <b>Object to processing of Your Personal Data.</b> This right exists where We are relying on a
                    legitimate interest as the legal basis for Our processing and there is something about Your
                    particular situation, which makes You want to object to our processing of Your Personal Data on this
                    ground. You also have the right to object where We are processing Your Personal Data for direct
                    marketing purposes.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b> Request erasure of Your Personal Data.</b> You have the right to ask Us to delete or remove
                    Personal Data when there is no good reason for Us to continue processing it.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>Request the transfer of Your Personal Data.</b> We will provide to You, or to a third-party You
                    have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note
                    that this right only applies to automated information which You initially provided consent for Us to
                    use or where We used the information to perform a contract with You.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>Withdraw Your consent.</b> You have the right to withdraw Your consent on using your Personal
                    Data. If You withdraw Your consent, We may not be able to provide You with access to certain
                    specific functionalities of the Service.
                </DoctTypography>
            </ul>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Exercising of Your Data Protection Rights
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us.
                Please note that we may ask You to verify Your identity before responding to such requests. If You make
                a request, We will try our best to respond to You as soon as possible. You have the right to complain to
                a Data Protection Authority about Our collection and use of Your Personal Data.
            </DoctTypography>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Children's Privacy
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                Our Service does not address anyone under the age of 18. We do not knowingly collect personally
                identifiable information from anyone under the age of 18. If You are a parent or guardian and You are
                aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We
                have collected Personal Data from anyone under the age of 18 without verification of parental consent,
                We take steps to remove that information from Our servers. If We need to rely on consent as a legal
                basis for processing Your information and Your country requires consent from a parent, We may require
                Your parent's consent before We collect and use that information.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Links to Other Websites
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                Our Service may contain links to other websites that are not operated by Us. If You click on a third
                party link, You will be directed to that third party's site. We strongly advise You to review the
                Privacy Policy of every site You visit. We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites or services.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Changes to this Privacy Policy
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
                Privacy Policy on this page. We will let You know via email and/or a prominent notice on Our Service,
                prior to the change becoming effective and update the "Last updated" date at the top of this Privacy
                Policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this
                Privacy Policy are effective when they are posted on this page.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Contact Us
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 ">
                If you have any questions about this Privacy Policy, You can contact us:
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    By visiting this page on our website:
                    <a href={`${doctUrls?.doct_brand_url}/contact-us`}>https://www.docthub.com/contact-us</a>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    By sending us an email:<a href="mailto:office@docthub.com ">office@docthub.com </a>
                </DoctTypography>
            </ul>
        </DoctModal>
    );
};

export default PrivacyPolicy;
