import React from 'react';
import DoctEventDetailDefaultImg from './components/EventDetailDefaultImg/DoctEventDetailDefaultImg';
import './EventDetails.scss';
import { Button, Tooltip } from '@material-ui/core';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { DoctIcon, DoctTypography } from '@doct-react/core';
import TodayIcon from '@mui/icons-material/Today';
import EventStatus from './components/Helper/enums/EventStatus';
import EventDayCounter from './components/EventDayCounter/EventDayCounter';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { DoctSocialShare } from '../../..';

dayjs.extend(isSameOrAfter);

interface DoctEventBannerProps {
    eventDetails?: any;
    onBrochureDownload?: () => void;
    handleBookmarkClick?: () => void;
    isBookMarkValue?: boolean;
}

// Hook
export function useWindowSize(): Array<{ width: number; height: number }> {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener('resize', handleResize);
        // window.addEventListener('scroll', handleScroll)

        // Call handler right away so state gets updated with initial window size
        handleResize();
        // handleScroll()
        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
            // window.removeEventListener('scroll', handleScroll)
        };
    }, []); // Empty array ensures that effect is only run on mount

    return [windowSize];
}

const DoctEventDetailBanner: React.FC<DoctEventBannerProps> = ({
    eventDetails: {
        venue,
        image,
        isPartneredEvent,
        startDate,
        title,
        type,
        specialities = [],
        name = '',
        brochureUrl,
        hasBrochure,
        endDate,
        status,
        organizer,
        views,
        numberOfGathering,
        noOfSeatsLeft,
    } = {},
    onBrochureDownload = () => null,
    handleBookmarkClick = () => null,
    isBookMarkValue,
}) => {
    const isExpired = status === EventStatus.COMPLETED;
    const size = useWindowSize();
    const { width } = size[0];

    return (
        <>
            <div className="event-card event-details-card border-radius">
                <div className="event-card-image-wrapper overflow-hidden">
                    {image?.coverImageUrl != null ? (
                        <img src={image?.coverImageUrl} alt="event-details" className="w-100 h-100 object-fit-cover" />
                    ) : (
                        <DoctEventDetailDefaultImg
                            name={name}
                            venue={venue}
                            organizer={organizer}
                            startDate={startDate}
                            endDate={endDate}
                            eventCard={undefined}
                        />
                    )}
                </div>

                {isPartneredEvent && (
                    <svg
                        className="event-card-brand-logo position-absolute primary-icon"
                        id="D_LOGO_BADGE"
                        data-name="D LOGO BADGE"
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="25"
                        viewBox="0 0 29 25"
                    >
                        <path
                            id="base"
                            d="M0,0H26a3,3,0,0,1,3,3V22a3,3,0,0,1-3,3H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                            fill="rgba(0,0,0,0.4)"
                        />
                        <g id="Logo" transform="translate(7.778 5.834)">
                            <path
                                id="Path_716"
                                data-name="Path 716"
                                d="M82.162,110.386h2.951a8.156,8.156,0,0,1,5.469,1.7,5.8,5.8,0,0,1,1.988,4.657,6.25,6.25,0,0,1-1.916,4.841,7.473,7.473,0,0,1-5.26,1.762H82.162Zm2.395,10.725h.838A5.131,5.131,0,0,0,88.923,120a3.977,3.977,0,0,0,1.244-3.134,3.879,3.879,0,0,0-1.317-3.143,5.649,5.649,0,0,0-3.737-1.108h-.556Z"
                                transform="translate(-82.162 -110.376)"
                                fill="#fff"
                            />
                            <path
                                id="Path_717"
                                data-name="Path 717"
                                d="M302.5,110.155h-4.122v1.832h4.11v4.11h1.787v-5.942Z"
                                transform="translate(-288.717 -110.155)"
                                fill="#fff"
                            />
                        </g>
                    </svg>
                )}
                {width >= 768 ? (
                    <div className="event-card-body px-3 py-2 bg-white">
                        {!dayjs().isSameOrAfter(dayjs(startDate)) && (
                            <EventDayCounter eventStartDate={dayjs(startDate).format('YYYY/MM/DDTHH:mm:ss')} />
                        )}

                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center mb-1">
                                {type && (
                                    <DoctTypography
                                        variant="overline"
                                        textTransform="uppercase"
                                        fontWeight="medium"
                                        className="text-grey-600 d-inline-flex align-items-center"
                                    >
                                        {type}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="13"
                                            height="14"
                                            viewBox="0 0 13 14"
                                        >
                                            <rect style={{ fill: 'none' }} className="a" width="13" height="14" />
                                            <path
                                                style={{ fill: 'none', stroke: '#aaa' }}
                                                className="b"
                                                d="M401.59,306.98l1.814,4.014L401.59,315"
                                                transform="translate(-396 -304)"
                                            />
                                        </svg>
                                    </DoctTypography>
                                )}
                                <span>
                                    {specialities && (
                                        <DoctTypography
                                            variant="textLabel2"
                                            className="d-inline-block text-grey-600 m-0"
                                        >
                                            {specialities[0]}
                                        </DoctTypography>
                                    )}
                                    {specialities && specialities.length > 1 && (
                                        <DoctTypography
                                            variant="textLabel2"
                                            className="event-card-tag d-inline-block text-grey-600 m-0"
                                        >
                                            , +{specialities.length - 1}
                                        </DoctTypography>
                                    )}
                                </span>
                                {isExpired && (
                                    <DoctTypography
                                        variant="textLabel2"
                                        textTransform="uppercase"
                                        className="event-closed-text ml-auto mb-0"
                                    >
                                        Completed
                                    </DoctTypography>
                                )}
                            </div>
                            <div className="mt-2">
                                <svg
                                    className="mb-sm-n1 mb-n1"
                                    id="round-location_on-24px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                >
                                    <g id="Bounding_Boxes">
                                        <path id="Path_788" data-name="Path 788" d="M0,0H14V14H0Z" fill="none" />
                                    </g>
                                    <g id="Rounded_1_" transform="translate(2.917 1.167)">
                                        <path
                                            id="Path_789"
                                            data-name="Path 789"
                                            d="M9.083,2A4.08,4.08,0,0,0,5,6.083c0,2.432,2.578,5.787,3.64,7.064a.579.579,0,0,0,.892,0c1.056-1.277,3.634-4.632,3.634-7.064A4.08,4.08,0,0,0,9.083,2Zm0,5.542a1.458,1.458,0,1,1,1.458-1.458A1.459,1.459,0,0,1,9.083,7.542Z"
                                            transform="translate(-5 -2)"
                                            fill="#717171"
                                        />
                                    </g>
                                </svg>
                                <DoctTypography
                                    variant="textLabel2"
                                    component="h1"
                                    className="my-1 ml-1 d-inline-block text-grey-600"
                                >
                                    {venue?.city}, {venue?.country}
                                </DoctTypography>
                            </div>
                        </div>
                        <DoctTypography variant="h6" className="w-75 mb-1 mt-sm-n2" component="h1">
                            {name}
                        </DoctTypography>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-between align-items-sm-center flex-column flex-sm-row text-uppercase">
                                <div className="mb-0 d-inline-flex align-items-center">
                                    <TodayIcon className="icon-18 vertical-align-middle" />
                                    <DoctTypography
                                        variant="textLabel1"
                                        fontWeight="medium"
                                        className="pl-2 event-card-date m-0"
                                    >
                                        {dayjs(startDate).format('DD MMM YYYY')} -{' '}
                                        {dayjs(endDate).format('DD MMM YYYY')}
                                    </DoctTypography>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <DoctTypography variant="textLabel2" className="mt-2 text-grey-600">
                                {views} Views
                            </DoctTypography>
                            {numberOfGathering === 'Limited' && noOfSeatsLeft === null && (
                                <DoctTypography variant="textLabel2" className="mt-2 text-danger">
                                    All Seats Are Occupied !!
                                </DoctTypography>
                            )}
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center mt-n2 mr-n2 mb-1">
                                    {hasBrochure ? (
                                        <>
                                            <Button
                                                className="banner_brochure_container for-high-index"
                                                onClick={onBrochureDownload}
                                            >
                                                <svg
                                                    className="dark-grey-text event-details-card-download mr-1"
                                                    id="Icon"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path
                                                        id="Path_225"
                                                        data-name="Path 225"
                                                        d="M16.827,10.778v6.049H4.728V10.778H3v6.049a1.733,1.733,0,0,0,1.728,1.728h12.1a1.733,1.733,0,0,0,1.728-1.728V10.778Zm-5.185.579,2.238-2.23L15.1,10.346l-4.321,4.321L6.457,10.346,7.675,9.127l2.238,2.23V3h1.728Z"
                                                        transform="translate(-0.5 -0.5)"
                                                        fill="#717171"
                                                    />
                                                    <path
                                                        id="Path_226"
                                                        data-name="Path 226"
                                                        d="M0,0H20V20H0Z"
                                                        fill="none"
                                                    />
                                                </svg>
                                                <span className="text-grey-600">Brochure</span>
                                            </Button>
                                            <span className="pr-3 mr-1 pl-3">
                                                <DoctSocialShare />
                                            </span>
                                            <span
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleBookmarkClick();
                                                }}
                                                className="mt-1 mr-3 cursor-pointer for-high-index"
                                            >
                                                <DoctIcon
                                                    width="24"
                                                    height="24"
                                                    name={isBookMarkValue ? 'bookmark' : 'bookmarkOutline'}
                                                    fill={isBookMarkValue ? '#00A0C0' : '#717171'}
                                                />
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="pr-3 pl-3 mr-1">
                                                <DoctSocialShare />
                                            </span>
                                            <span
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleBookmarkClick();
                                                }}
                                                className="mt-1 mr-3 cursor-pointer"
                                            >
                                                <DoctIcon
                                                    width="24"
                                                    height="24"
                                                    name={isBookMarkValue ? 'bookmark' : 'bookmarkOutline'}
                                                    fill={isBookMarkValue ? '#00A0C0' : '#717171'}
                                                />
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="event-card-body px-3 bg-white">
                        {!dayjs().isSameOrAfter(dayjs(startDate)) && (
                            <EventDayCounter eventStartDate={dayjs(startDate).format('YYYY/MM/DDTHH:mm:ss')} />
                        )}

                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center mb-1 mb-n1">
                                {type && (
                                    <DoctTypography
                                        variant="overline"
                                        textTransform="uppercase"
                                        fontWeight="medium"
                                        className="text-grey-600 d-inline-flex align-items-center"
                                    >
                                        {type}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="13"
                                            height="14"
                                            viewBox="0 0 13 14"
                                        >
                                            <rect style={{ fill: 'none' }} className="a" width="13" height="14" />
                                            <path
                                                style={{ fill: 'none', stroke: '#aaa' }}
                                                className="b"
                                                d="M401.59,306.98l1.814,4.014L401.59,315"
                                                transform="translate(-396 -304)"
                                            />
                                        </svg>
                                    </DoctTypography>
                                )}
                                <span>
                                    {specialities && (
                                        <DoctTypography variant="textLabel2" className="d-inline-block text-grey-600">
                                            {specialities[0]}
                                        </DoctTypography>
                                    )}
                                    {specialities && specialities.length > 1 && (
                                        <DoctTypography
                                            variant="textLabel2"
                                            className="event-card-tag d-inline-block text-grey-600 m-0"
                                        >
                                            , +{specialities.length - 1}
                                        </DoctTypography>
                                    )}
                                </span>
                                {isExpired && (
                                    <DoctTypography
                                        variant="textLabel2"
                                        textTransform="uppercase"
                                        className="event-closed-text ml-auto mb-0"
                                    >
                                        Completed
                                    </DoctTypography>
                                )}
                            </div>
                        </div>
                        <DoctTypography variant="h6" component="h1" className="w-75 mb-2 mt-sm-n2">
                            {name}
                        </DoctTypography>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-between align-items-sm-center flex-column flex-sm-row text-uppercase">
                                <div className="mb-0 d-inline-flex align-items-center">
                                    <TodayIcon className="icon-18 vertical-align-middle" />
                                    <DoctTypography
                                        variant="textLabel1"
                                        fontWeight="medium"
                                        className="pl-2 event-card-date m-0"
                                    >
                                        {dayjs(startDate).format('DD MMM YYYY')} -{' '}
                                        {dayjs(endDate).format('DD MMM YYYY')}
                                    </DoctTypography>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2">
                            <svg
                                className="mb-sm-n1 mb-n1"
                                id="round-location_on-24px"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 14 14"
                            >
                                <g id="Bounding_Boxes">
                                    <path id="Path_788" data-name="Path 788" d="M0,0H14V14H0Z" fill="none" />
                                </g>
                                <g id="Rounded_1_" transform="translate(2.917 1.167)">
                                    <path
                                        id="Path_789"
                                        data-name="Path 789"
                                        d="M9.083,2A4.08,4.08,0,0,0,5,6.083c0,2.432,2.578,5.787,3.64,7.064a.579.579,0,0,0,.892,0c1.056-1.277,3.634-4.632,3.634-7.064A4.08,4.08,0,0,0,9.083,2Zm0,5.542a1.458,1.458,0,1,1,1.458-1.458A1.459,1.459,0,0,1,9.083,7.542Z"
                                        transform="translate(-5 -2)"
                                        fill="#717171"
                                    />
                                </g>
                            </svg>
                            <DoctTypography variant="textLabel2" className="my-1 ml-1 d-inline-block text-grey-600">
                                {venue?.city}, {venue?.country}
                            </DoctTypography>
                        </div>
                        <div className="d-flex justify-content-between">
                            <DoctTypography variant="textLabel2" className="mt-2 text-grey-600 test">
                                {views} Views
                            </DoctTypography>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    {hasBrochure ? (
                                        <>
                                            <Button onChange={onBrochureDownload}>
                                                <svg
                                                    className="dark-grey-text event-details-card-download mr-1"
                                                    id="Icon"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path
                                                        id="Path_225"
                                                        data-name="Path 225"
                                                        d="M16.827,10.778v6.049H4.728V10.778H3v6.049a1.733,1.733,0,0,0,1.728,1.728h12.1a1.733,1.733,0,0,0,1.728-1.728V10.778Zm-5.185.579,2.238-2.23L15.1,10.346l-4.321,4.321L6.457,10.346,7.675,9.127l2.238,2.23V3h1.728Z"
                                                        transform="translate(-0.5 -0.5)"
                                                        fill="#717171"
                                                    />
                                                    <path
                                                        id="Path_226"
                                                        data-name="Path 226"
                                                        d="M0,0H20V20H0Z"
                                                        fill="none"
                                                    />
                                                </svg>
                                                <span className="text-grey-600 mr-3">Brochure</span>
                                            </Button>
                                            <span className="pr-3 pl-3 mr-1">
                                                <DoctSocialShare />
                                            </span>
                                        </>
                                    ) : (
                                        <DoctSocialShare />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default DoctEventDetailBanner;
