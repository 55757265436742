import React, { Children } from 'react';

import { DoctContainer, DoctLogo, DoctIcon } from '@doct-react/core';

import DoctUserMenuDrawer from './DoctUserMenuDrawer';
import LoginSignUpButton from './LoginSignUpButton';

import { UserMenuPropTypes } from './PropTypes';
import DoctLoading from '../Loadings/DoctLoading';
import DoctUserMenu from '../DoctUserMenu/DoctUserMenu';
import { AvatarOrProfileContainer } from './Branding/DoctTopBarBranding';
import { ACCOUNT_TYPE_BUISSNESS } from './constants';
import DoctUserMenuEnterprise from '../DoctUserMenu/DoctUserMenuEnterprise/DoctUserMenuEnterprise';

interface DoctTopBarProps {
    onDoctLogoClick?: () => void;
    userMenu?: UserMenuPropTypes;
    className?: string;
    children?: JSX.Element;
    handleLoginRedirection: () => any;
    loading?: boolean;
    additionalButtonText?: string;
    onAdditionalButtonClicked?: () => any;
    name: string;
    image: string;
    professionalTitle: any;
    handleLogout: any;
    defaultImgAvatarEnterprise?: string;
    continueUrl: string;
}

interface AvatarProps {
    src: string;
}

export const Avatar: React.FC<AvatarProps> = ({ src }) => {
    return (
        <span className="doct-header-avatar d-inline-flex justify-content-center align-items-center overflow-hidden">
            <img src={src} alt="dp" className="w-100 h-100 object-fit-cover" />
        </span>
    );
};

const DoctTopBar: React.FC<DoctTopBarProps> = ({
    onDoctLogoClick,
    userMenu,
    className,
    children,
    handleLoginRedirection,
    loading,
    additionalButtonText,
    onAdditionalButtonClicked,
    name,
    image,
    professionalTitle,
    handleLogout,
    defaultImgAvatarEnterprise,
    continueUrl,
}) => {
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <header className={`doct-header bg-white${className ? ` ${className}` : ''}`}>
                <DoctUserMenuDrawer userMenu={userMenu} />
                <DoctContainer>
                    <div className="d-flex  align-items-center">
                        <span onClick={onDoctLogoClick} className="doct-header_logo cursor-pointer">
                            <DoctLogo rest={true} />
                        </span>
                        {children}
                        {loading ? (
                            <span className="d-inline-flex align-items-center ml-auto doct-header-login-area">
                                <DoctLoading />
                            </span>
                        ) : Object.keys(userMenu?.userDetails || {})?.length >= 1 ? (
                            <>
                                {/* @ts-ignore */}
                                <div className="ml-auto cursor-pointer reset-line-height" onClick={handleClick}>
                                    <AvatarOrProfileContainer
                                        userMenu={userMenu}
                                        defaultImgAvatarEnterprise={defaultImgAvatarEnterprise}
                                    />
                                </div>
                                {userMenu?.userDetails?.accountType == ACCOUNT_TYPE_BUISSNESS ? (
                                    <DoctUserMenuEnterprise
                                        handleLogout={handleLogout}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        userDetails={userMenu?.userDetails}
                                        defaultImgAvatarEnterprise={defaultImgAvatarEnterprise}
                                    />
                                ) : (
                                    <DoctUserMenu
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        name={name}
                                        image={image}
                                        professionalTitle={professionalTitle}
                                        handleLogout={handleLogout}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <LoginSignUpButton
                                    continueUrl={continueUrl}
                                    handleLoginRedirection={handleLoginRedirection}
                                    additionalButtonText={additionalButtonText}
                                    onAdditionalButtonClicked={onAdditionalButtonClicked}
                                />
                            </>
                        )}
                    </div>
                </DoctContainer>
            </header>
        </>
    );
};

export default DoctTopBar;
