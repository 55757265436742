import { DoctButton, DoctContainer, DoctIcon, DoctTypography } from '@doct-react/core';
import React from 'react';
import { useOuterClick } from '../../AppBar/DoctAppBarDropdown';

import {
    BaseNavigationMenuProps,
    CompanyInfoBoxProps,
    FeatureBoxResponsiveProps,
    HeadingAndContentProps,
    LayoutNavigationMenuProps,
    LinkItemProps,
    NavigationItemColumnProps,
    NavigationMenuContainerProps,
    SharePlayAppStoreProps,
} from '../interfaces';

import '../DoctNavigationMenu.scss';

export const SharePlayAppStore: React.FC<SharePlayAppStoreProps> = ({
    classNamePlayStoreLinkMargin = 'ml-auto ml-sm-4 ml-md-auto',
}) => {
    return (
        <>
            <a
                href="https://play.google.com/store/apps/details?id=com.docthub.app"
                className={`${classNamePlayStoreLinkMargin} reset-line-height`}
            >
                <DoctIcon width="44" height="44" name="playStoreRound" />
            </a>
            <a
                href="https://apps.apple.com/in/app/docthub-health-career-more/id1625281489"
                className="ml-2 reset-line-height"
            >
                <DoctIcon width="44" height="44" name="appStoreRound" />
            </a>
        </>
    );
};

export const CompanyInfoBox: React.FC<CompanyInfoBoxProps> = ({ className, branding }) => {
    return (
        <div className={`navigation-menu-company-box d-flex flex-column ${className}`}>
            <DoctTypography variant="subtitle1" fontWeight="bold" className="my-0">
                Company
            </DoctTypography>
            <div className="d-flex flex-column flex-sm-row flex-md-column">
                {branding && window?.DoctUrls?.doct_brand_url && (
                    <a href={`${window.DoctUrls.doct_brand_url}/contact-us`}>
                        <DoctTypography
                            variant="subtitle1"
                            className={`mt-4 mb-0 text-primary font-weight-semibold pr-sm-5 pr-md-0 mb-sm-4 mb-md-0`}
                        >
                            {`Contact us>`}
                        </DoctTypography>
                    </a>
                )}
                {!branding && window?.DoctUrls?.doct_brand_url && (
                    <a href={window.DoctUrls.doct_brand_url}>
                        <DoctTypography
                            variant="subtitle1"
                            className={`mt-4 mb-0 text-primary font-weight-semibold pr-sm-5 pr-md-0 mb-sm-4 mb-md-0`}
                        >
                            {`Docthub.com>`}
                        </DoctTypography>
                    </a>
                )}
                {window?.DoctUrls?.doct_enterprise_solutions_url && (
                    <a href={window.DoctUrls.doct_enterprise_solutions_url}>
                        <DoctTypography
                            variant="subtitle1"
                            className={`mt-4 mb-0 text-primary font-weight-semibold pl-sm-3 pl-md-0 mb-4 mb-md-0`}
                        >
                            {`Docthub Enterprise>`}
                        </DoctTypography>
                    </a>
                )}
            </div>
            <div className={`navigation-menu-company-box-divider mt-2 mt-md-auto`} />
            <div
                className={`d-flex align-items-center navigation-menu-company-box-download-box justify-content-sm-center`}
            >
                <div>
                    <DoctTypography
                        variant={window?.innerWidth > 1279 ? 'subtitle1' : 'textLabel1'}
                        className="my-0"
                        fontWeight="bold"
                    >
                        Download
                    </DoctTypography>
                    <DoctTypography
                        variant={window?.innerWidth > 1279 ? 'subtitle2' : 'textLabel2'}
                        className={`my-0 font-weight-semibold`}
                    >
                        Docthub Mobile App
                    </DoctTypography>
                </div>
                <SharePlayAppStore />
            </div>
        </div>
    );
};

export const EntperiseNavigation: React.FC = () => {
    return (
        <div className="d-flex">
            <LinkItem
                href={window?.DoctUrls?.doct_enterprise_solutions_url && window.DoctUrls.doct_enterprise_solutions_url}
                title="Docthub Enterprise"
                description="A dashboard for organizational admin"
                iconName="enterpriseFilled"
                className="mb-md-0"
            />
            <LinkItemOutLine />
        </div>
    );
};

export const NavigationItemColumn: React.FC<NavigationItemColumnProps> = ({ tiny, children }) => {
    return (
        <div
            className={`navigation-menu-link-column ${
                tiny ? 'navigation-menu-link-column-tiny' : ''
            } w-100 d-flex flex-wrap justify-content-between`}
        >
            {children}
        </div>
    );
};

export const FeatureBoxResponsive: React.FC<FeatureBoxResponsiveProps> = ({ href, title, iconName, className }) => {
    return (
        <a
            href={href}
            className={`feature-box d-inline-flex justify-content-center align-items-center flex-column ${className}`}
        >
            <DoctIcon width={'32'} height={'32'} name={iconName} />
            <DoctTypography
                variant="textLabel1"
                className="navigation-menu-link-item-text-responsive mb-0 text-grey-800 font-weight-semibold"
            >
                {title}
            </DoctTypography>
        </a>
    );
};

export const LinkItemOutLine: React.FC = () => {
    return (
        <a
            href={`${window.DoctUrls?.doct_enterprise_url}/dashboard`}
            className={
                'navigation-menu-link-item navigation-menu-link-item-outline navigation-menu-btn-primary700 d-flex align-items-center cursor-pointer'
            }
        >
            <DoctTypography variant="textLabel2" className="text-grey-600">
                Go to your Enterprise Dashboard
            </DoctTypography>
            <DoctButton text="Enterprise Login >" size="medium" />
        </a>
    );
};

export const LinkItem: React.FC<LinkItemProps> = ({ href, title, description, iconName, tiny, className }) => {
    return (
        <a
            href={href}
            className={`navigation-menu-link-item ${
                tiny ? 'navigation-menu-link-item-tiny' : ''
            } mb-sm-1 d-flex align-items-center flex-column flex-sm-row justify-content-center justify-content-sm-start align-items-center ${className}`}
        >
            <DoctIcon
                width={window?.innerWidth < 768 ? '32' : '44'}
                height={window?.innerWidth < 768 ? '32' : '44'}
                name={iconName}
            />
            <div className={`navigation-menu-link-item-text`}>
                <DoctTypography
                    variant={window?.innerWidth < 768 ? 'textLabel1' : 'subtitle1'}
                    className={`my-0 text-grey-800 font-weight-semibold`}
                >
                    {title}
                </DoctTypography>
                {description && (
                    <DoctTypography variant="subtitle3" className="mb-0 mt-1 text-grey-500 d-none d-sm-block">
                        {description}
                    </DoctTypography>
                )}
            </div>
        </a>
    );
};

export const HeadingAndContent: React.FC<HeadingAndContentProps> = ({ heading, children, isBrandingView }) => {
    return (
        <>
            <DoctTypography
                variant={window?.innerWidth < 768 && !isBrandingView ? 'textLabel1' : 'subtitle1'}
                className={`mb-3 mt-0 pl-sm-3 ${
                    isBrandingView
                        ? 'navigation-menu-responsive-heading-branding-variant'
                        : 'navigation-menu-responsive-heading'
                } `}
                fontWeight="bold"
            >
                {heading}
            </DoctTypography>
            {children}
        </>
    );
};

export const LayoutNavigationMenu: React.FC<LayoutNavigationMenuProps> = ({ children, branding }) => {
    return (
        <>
            <div className={`navigation-menu-product-box d-flex w-100 flex-column flex-sm-row`}>{children}</div>
            <CompanyInfoBox branding={branding} />
        </>
    );
};

export const LayoutNavigationMenuBranding: React.FC<LayoutNavigationMenuProps> = ({ children, branding }) => {
    return (
        <>
            <div className="navigation-menu-product-box">{children}</div>
            <CompanyInfoBox branding={branding} className="ml-auto" />
        </>
    );
};

export const ProductGridLayoutBrandingPage: React.FC = ({ children }) => {
    return <div className={`navigation-menu-enterprise-feature`}>{children}</div>;
};

export const BaseNavigationMenu: React.FC<BaseNavigationMenuProps> = ({ children, onClosePressed, setMenuOpen }) => {
    const innerRef = useOuterClick((e) => {
        onClosePressed(e);
        if (setMenuOpen) {
            setMenuOpen(false);
        }
    });
    return (
        <DoctContainer>
            <div
                className={`navigation-menu-base bg-white overflow-hidden d-flex flex-column flex-md-row position-relative`}
                ref={innerRef}
            >
                {children}
            </div>
        </DoctContainer>
    );
};

export const NavigationMenuContainer: React.FC<NavigationMenuContainerProps> = ({
    className,
    children,
    adjustSpacing,
}) => {
    return (
        <div className={`navigation-menu ${className}`} style={{ paddingRight: `${adjustSpacing || 0}px` }}>
            {children}
        </div>
    );
};
