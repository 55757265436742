import React, { useState } from 'react';
import { DoctButton, DoctContainer, DoctIcon, DoctLogo, DoctLogoEnterprise, DoctTypography } from '@doct-react/core';
import DoctUserMenu from '../../DoctUserMenu/DoctUserMenu';
import DoctLoading from '../../Loadings/DoctLoading';
import LoginSignUpButton, { handleRedirection } from '../LoginSignUpButton';

import DoctNavigationMenuBrandingApp from '../../NavigationMenu/DoctNavigationMenuBrandingApp';
import DoctNavigationMenuUserAppUserApp from '../../NavigationMenu/DoctNavigationMenuUserApp';
import DoctNavigationMenuEnterpriseAppResponsive from '../../NavigationMenu/Components/DoctNavigationMenuEnterpriseResponsive';
import { Avatar } from '../DoctTopBar';
import DoctUserMenuEnterprise from '../../DoctUserMenu/DoctUserMenuEnterprise/DoctUserMenuEnterprise';

import { ACCOUNT_TYPE_BUISSNESS } from '../constants';

import './DoctTopBarBranding.scss';
import { AvatarOrProfileContainerProps } from '../PropTypes';

import { MenuItem } from '@mui/material';

import MenuMUI from '@mui/material/Menu';
import { MenuLinkProps } from '../../DoctUserMenu/DoctUserMenuEnterprise/interfaceDoctUserMenuEnterprise';

interface NavLinkComponentProps {
    className?: string;
    href?: string;
    to?: string;
}
interface DoctTopBarBrandingProps {
    enterprise: boolean;
    navLinks?: Array<{ name: string; href?: string; hasMenu?: true; component?: React.FC<NavLinkComponentProps> }>;
    userMenu?: any;
    indexUrl?: string;
    loading?: boolean;
    name?: string;
    image?: any;
    handleLogout: () => void;
    handleLoginRedirection: () => void;
    onCreateAccountPressed?: () => void;
    defaultImgAvatarEnterprise?: any;
    continueUrl?: string;
}

const NavigationArea: React.FC = ({ children }) => {
    return <div className="doct-navigation-area position-fixed">{children}</div>;
};

const NavLinkText: React.FC = ({ children }) => {
    return (
        <DoctTypography variant="subtitle2" className="text-grey-600 my-0 font-weight-semi-bold" letterSpacing={0.22}>
            {children}
        </DoctTypography>
    );
};

export const getScrollbarWidth = (): number => {
    return window.innerWidth - window.document.documentElement.clientWidth;
};

export const handleScrollbar = (menuOpen: boolean, scrollBarWidth: number): void => {
    const body = document.body;
    const fixedElList = document.querySelectorAll('.doct-fixed-position-element') as any;

    function setPadding(el) {
        el.style.setProperty('padding-right', `${scrollBarWidth}px`, 'important');
    }
    function resetPadding(el) {
        el.style.setProperty('padding-Right', '0', 'important');
    }

    if (menuOpen) {
        body.style.setProperty('overflow', 'hidden', 'important');
        setPadding(body);
        for (const elem of fixedElList) {
            setPadding(elem);
        }
    } else {
        body.style.setProperty('overflow', 'initial', 'important');
        resetPadding(body);
        for (const elem of fixedElList) {
            resetPadding(elem);
        }
    }
};

export const AvatarOrProfileContainer: React.FC<AvatarOrProfileContainerProps> = ({
    userMenu,
    defaultImgAvatarEnterprise,
}) => {
    if (userMenu?.userDetails?.accountType == ACCOUNT_TYPE_BUISSNESS) {
        return <Avatar src={userMenu?.userDetails?.tenant?.organizationLogoUrl || defaultImgAvatarEnterprise} />;
    }

    return userMenu?.userDetails?.image ? (
        <Avatar src={userMenu.userDetails.image} />
    ) : (
        <DoctIcon className="profile-picture" name="defaultAvatar" width="32px" />
    );
};

const DoctTopBarBranding: React.FC<DoctTopBarBrandingProps> = ({
    enterprise = false,
    navLinks,
    userMenu = {},
    loading,
    handleLogout,
    handleLoginRedirection,
    onCreateAccountPressed,
    defaultImgAvatarEnterprise,
    indexUrl = '/',
    continueUrl,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showEnterpriseAppNavigationMenu, setEnterpriseAppNavigationMenu] = React.useState(false);
    const [showEnterpriseAppNavigationMenuResponsive, setEnterpriseAppNavigationMenuResponsive] = React.useState(false);
    const [showUserAppNaviationMenu, setShowUserAppNaviationMenu] = React.useState(false);
    const [activeMenuName, setActiveMenuName] = React.useState(null);
    const [scrollBarWidthSize, setScrollBarWidthSize] = useState(0);
    const open = Boolean(anchorEl);

    const menuTogalarWithMangeScrollbar = (fn, showMenu, name?: string) => {
        fn(showMenu);
        const scrollBarWidth = getScrollbarWidth();
        setScrollBarWidthSize(scrollBarWidth);

        handleScrollbar(showMenu, scrollBarWidth);
        if (showMenu) {
            setActiveMenuName(name);
        } else {
            setActiveMenuName(null);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuClickHandler = (name) => {
        if (name == 'For Organizations') {
            if (showUserAppNaviationMenu) {
                menuTogalarWithMangeScrollbar(setShowUserAppNaviationMenu, false);
            }

            menuTogalarWithMangeScrollbar(setEnterpriseAppNavigationMenu, !showEnterpriseAppNavigationMenu, name);
            return;
        }
        if (name == 'Go to') {
            if (showEnterpriseAppNavigationMenu) {
                menuTogalarWithMangeScrollbar(setEnterpriseAppNavigationMenu, false);
            }
            menuTogalarWithMangeScrollbar(setShowUserAppNaviationMenu, !showUserAppNaviationMenu, name);
            return;
        }
    };

    const classNameLinks = 'doct-top-bar-branding-navigation d-inline-flex';

    const [show, setShow] = React.useState(true);
    const [lastScrollY, setLastScrollY] = React.useState(0);

    const controlNavbar = () => {
        if (typeof window !== 'undefined') {
            const body = document.body;

            if (window.scrollY > lastScrollY) {
                setShow(false);
                body.classList.add('is-scrolling');
            } else {
                setShow(true);
                body.classList.remove('is-scrolling');
            }

            setLastScrollY(window.scrollY);
        }
    };

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);

            // cleanup function
            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }
    }, [lastScrollY]);

    const [doctUrls, setDoctUrls] = useState(null);

    const MenuLayout: React.FC = ({ children }) => {
        return <div className="overflow-hidden menu-dropdown-width">{children}</div>;
    };

    const [menuOpen, setMenuOpen] = useState(null);

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const linksArray = [
        {
            title: window && window.innerWidth > 767 ? 'User account' : 'Create User account',
            subTitle: 'To find jobs, courses, events',
            onClick: () => {
                if (onCreateAccountPressed) {
                    onCreateAccountPressed();
                } else {
                    handleRedirection('create-account', continueUrl);
                }
            },
        },

        {
            title: window && window.innerWidth > 767 ? 'Enterprise Account' : 'Create Enterprise Account',
            subTitle: 'For Recruiters, Institutes, Event organisers.',
            href: `${window.DoctUrls?.doct_enterprise_account_url}`,
        },
    ];

    const MenuLink: React.FC<MenuLinkProps> = ({ el: Element, href, onClick, title, subTitle, hideRightIcon }) => {
        const elProps = {};
        if (href) elProps['href'] = href;
        return (
            <MenuItem>
                <Element
                    {...elProps}
                    onClick={onClick}
                    className={'doct-user-menu-enterprise-link d-flex align-items-center cursor-pointer w-100'}
                >
                    <div className="dropdown-text-width">
                        <DoctTypography variant={'textLabel1'} className="text-grey-800 m-0 font-weight-medium">
                            {title}
                        </DoctTypography>
                        <DoctTypography variant={'textLabel2'} className="text-grey-400 m-0 text-wrap-balance">
                            {subTitle}
                        </DoctTypography>
                    </div>
                    {!hideRightIcon && (
                        <DoctIcon name={'right'} width={'24'} height={'24'} className={'ml-auto text-grey-400'} />
                    )}
                </Element>
            </MenuItem>
        );
    };

    const MenuDropDown: React.FC = ({ children }) => {
        return (
            <MenuMUI
                id="basic-menu"
                className="menu-reset login-create-account-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {children}
            </MenuMUI>
        );
    };

    return (
        <>
            <header
                className={`doct-top-bar-branding doct-fixed-position-element bg-white ${
                    show ? '' : 'doct-top-bar-branding-scrolling'
                }`}
            >
                <DoctContainer>
                    <div className="doct-top-bar-branding-base d-flex align-items-center">
                        <a href={indexUrl} className="doct-top-bar-branding-logo-column reset-line-height">
                            {enterprise ? (
                                <span className="d-inline-flex responsive-logo">
                                    <DoctLogoEnterprise />
                                </span>
                            ) : (
                                <DoctLogo />
                            )}
                        </a>
                        <nav className="doct-top-bar-branding-navigation-column d-none d-md-flex mx-sm-auto">
                            {navLinks?.map(({ href, name, hasMenu, component: Component }) => {
                                if (Component)
                                    return (
                                        <Component
                                            className={`${classNameLinks} ${
                                                activeMenuName == name ? 'doct-top-bar-branding-navigation-active' : ''
                                            }`}
                                            href={href}
                                            to={href}
                                        >
                                            <NavLinkText>{name}</NavLinkText>
                                        </Component>
                                    );

                                return (
                                    <a
                                        href={href || 'javascript:void(0)'}
                                        key={href}
                                        onClick={(e) => {
                                            // e.preventDefault();
                                            e.stopPropagation();
                                            if (hasMenu) {
                                                menuClickHandler(name);
                                            }
                                        }}
                                        className={`${classNameLinks} ${
                                            activeMenuName == name ? 'doct-top-bar-branding-navigation-active' : ''
                                        }`}
                                    >
                                        <NavLinkText>{name}</NavLinkText>
                                        {hasMenu && (
                                            <DoctIcon
                                                width="18"
                                                height="18"
                                                name="down"
                                                className="text-grey-600 doct-top-bar-branding-navigation-down-icon"
                                            />
                                        )}
                                    </a>
                                );
                            })}
                        </nav>
                        <div className="doct-top-bar-branding-auth-column d-flex justify-content-end">
                            {loading ? (
                                <span className="d-inline-flex align-items-center ml-auto">
                                    <DoctLoading />
                                </span>
                            ) : Object.keys(userMenu?.userDetails || {})?.length >= 1 ? (
                                <>
                                    <div
                                        className="d-none d-md-flex align-items-center ml-auto"
                                        /* @ts-ignore */
                                        onClick={handleClick}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {!enterprise &&
                                            userMenu?.userDetails?.accountType == ACCOUNT_TYPE_BUISSNESS && (
                                                <a
                                                    href={`${window.DoctUrls.doct_dashboard_url}/dashboard`}
                                                    className="mr-3"
                                                >
                                                    <DoctButton
                                                        text="Go To Enterprise"
                                                        icon="right"
                                                        iconPosition="right"
                                                        variant="outlined"
                                                        size="medium"
                                                    />
                                                </a>
                                            )}
                                        <AvatarOrProfileContainer
                                            userMenu={userMenu}
                                            defaultImgAvatarEnterprise={defaultImgAvatarEnterprise}
                                        />
                                    </div>
                                    {userMenu?.userDetails?.accountType == ACCOUNT_TYPE_BUISSNESS ? (
                                        <DoctUserMenuEnterprise
                                            handleLogout={handleLogout}
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            userDetails={userMenu?.userDetails}
                                            defaultImgAvatarEnterprise={defaultImgAvatarEnterprise}
                                        />
                                    ) : (
                                        <DoctUserMenu
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            name={userMenu?.userDetails?.name}
                                            image={userMenu?.userDetails?.image}
                                            professionalTitle={userMenu?.userDetails?.professionalTitle}
                                            handleLogout={handleLogout}
                                        />
                                    )}
                                </>
                            ) : (
                                <div className="d-none d-md-flex justify-content-end align-items-stretch">
                                    <DoctButton
                                        onButtonClickHandler={handleButtonClick}
                                        size="medium"
                                        type="primary"
                                        variant="text"
                                        text="Create account"
                                        className="narrow-spacing mt-sm-n2"
                                    />
                                    <span className="divider-vertical doct-top-bar-branding-auth-column-divider-gap d-inline-block"></span>
                                    <span onClick={handleLoginRedirection}>
                                        <DoctTypography
                                            variant="textLabel1"
                                            fontWeight="medium"
                                            className="text-grey-600 cursor-pointer my-0"
                                            /* @ts-ignore */
                                        >
                                            Log in
                                        </DoctTypography>
                                    </span>
                                    <MenuDropDown>
                                        <MenuLayout>
                                            {linksArray.map(({ title, subTitle, onClick, href }) => (
                                                <MenuLink
                                                    {...{ title, subTitle, onClick, href }}
                                                    key={title}
                                                    el={'a'}
                                                />
                                            ))}
                                        </MenuLayout>
                                    </MenuDropDown>
                                </div>
                            )}
                            {window?.innerWidth < 1024 && (
                                <div className="d-flex d-md-none align-items-center justify-content-end reset-line-height">
                                    {loading ? (
                                        <span className="d-inline-flex align-items-center ml-auto doct-header-login-area">
                                            <DoctLoading />
                                        </span>
                                    ) : Object.keys(userMenu?.userDetails || {})?.length >= 1 ? (
                                        <>
                                            <div
                                                className="ml-auto"
                                                /* @ts-ignore */
                                                onClick={handleClick}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <AvatarOrProfileContainer
                                                    userMenu={userMenu}
                                                    defaultImgAvatarEnterprise={defaultImgAvatarEnterprise}
                                                />
                                            </div>
                                            {userMenu?.userDetails?.accountType == ACCOUNT_TYPE_BUISSNESS ? (
                                                <DoctUserMenuEnterprise
                                                    handleLogout={handleLogout}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    userDetails={userMenu?.userDetails}
                                                    defaultImgAvatarEnterprise={defaultImgAvatarEnterprise}
                                                />
                                            ) : (
                                                <DoctUserMenu
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    name={userMenu?.userDetails?.name}
                                                    image={userMenu?.userDetails?.image}
                                                    professionalTitle={userMenu?.userDetails?.professionalTitle}
                                                    handleLogout={handleLogout}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <LoginSignUpButton
                                                handleLoginRedirection={handleLoginRedirection}
                                                brandingVariant
                                                continueUrl={continueUrl}
                                                onCreateAccountPressed={onCreateAccountPressed}
                                            />
                                        </>
                                    )}
                                    {showEnterpriseAppNavigationMenu || showEnterpriseAppNavigationMenuResponsive ? (
                                        <span
                                            className="ml-4 reset-line-height"
                                            onClick={() => {
                                                if (showEnterpriseAppNavigationMenu) {
                                                    menuTogalarWithMangeScrollbar(
                                                        setEnterpriseAppNavigationMenu,
                                                        false,
                                                    );
                                                    return;
                                                }
                                                if (showEnterpriseAppNavigationMenuResponsive) {
                                                    menuTogalarWithMangeScrollbar(
                                                        setEnterpriseAppNavigationMenuResponsive,
                                                        false,
                                                    );
                                                    return;
                                                }
                                            }}
                                        >
                                            <DoctIcon width="24" height="24" name="close" className="text-primary" />
                                        </span>
                                    ) : (
                                        <span
                                            className="ml-4 reset-line-height"
                                            onClick={() => {
                                                if (enterprise) {
                                                    menuTogalarWithMangeScrollbar(
                                                        setEnterpriseAppNavigationMenuResponsive,
                                                        true,
                                                    );
                                                } else {
                                                    menuTogalarWithMangeScrollbar(setEnterpriseAppNavigationMenu, true);
                                                }
                                            }}
                                        >
                                            <DoctIcon
                                                width="24"
                                                height="24"
                                                name="hamburgerMenu"
                                                className="text-primary"
                                            />
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </DoctContainer>
            </header>
            {showEnterpriseAppNavigationMenu && (
                <NavigationArea>
                    <DoctNavigationMenuBrandingApp
                        adjustSpacing={scrollBarWidthSize}
                        onClosePressed={() => {
                            menuTogalarWithMangeScrollbar(setEnterpriseAppNavigationMenu, false);
                        }}
                    />
                </NavigationArea>
            )}
            {showUserAppNaviationMenu && (
                <NavigationArea>
                    <DoctNavigationMenuUserAppUserApp
                        adjustSpacing={scrollBarWidthSize}
                        branding
                        onClosePressed={() => {
                            menuTogalarWithMangeScrollbar(setShowUserAppNaviationMenu, false);
                        }}
                    />
                </NavigationArea>
            )}
            {showEnterpriseAppNavigationMenuResponsive && (
                <NavigationArea>
                    <DoctNavigationMenuEnterpriseAppResponsive />
                </NavigationArea>
            )}
        </>
    );
};

export default DoctTopBarBranding;
