import React, { useContext, useRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import Collapsible from '../Collapsible/Collapsible';
import Checkbox from './FilterCheckbox';

import DoctSearchInput from '../Form/SearchInput/SearchInput';

import { sortString } from '../../Config/utils';
import { InfiniteContext } from './InfiniteScroll.context';

interface SingleFilterProps {
    item: any;
    array: any;
    handleChange: any;
    showFilter: boolean;
    filters: any;
    index: number;
    filtersListArray?: any[];
    initialState?: boolean[];
    truncateOn?: number;
}

const SingleFilter: React.FC<SingleFilterProps> = ({
    item,
    array,
    handleChange,
    showFilter,
    filters,
    index,
    filtersListArray,
    initialState,
    truncateOn,
}) => {
    const { data, isLoading, isMore, loadDataChunk } = useContext(InfiniteContext);

    const loader = useRef(loadDataChunk);

    useEffect(() => {
        loader.current = loadDataChunk;
    }, [loadDataChunk]);

    const observer = useRef(
        new IntersectionObserver(
            (entries) => {
                const firstEntry = entries[0];
                if (firstEntry.isIntersecting) {
                    loader.current();
                }
            },
            { threshold: 1 },
        ),
    );

    //Set a ref using a callback.
    //This is used in conjunction with <div ref={setElement}></div>,
    //which is an empty div that is observed.

    const [element, setElement] = useState<HTMLElement>(null);
    const [isSearching, setIsSearching] = React.useState(false);

    useEffect(() => {
        //Wrap in if statement to avoid observing null.
        if (element) {
            observer?.current?.observe(element);
        }
        return () => {
            if (element) {
                observer.current.unobserve(element);
            }
        };
    }, [element]);

    const [arrays, setArrays] = React.useState(data);

    const { errors, control, watch } = useForm({
        mode: 'onChange',
    });

    const title = watch('filterSearch');

    React.useEffect(() => {
        if (title === undefined) {
            const results = data;
            setArrays(results);
        } else {
            const results =
                title && title.length >= 1
                    ? array.filter((item) => sortString(item.value).includes(sortString(title)))
                    : data;
            setArrays(results);
            if (title.length) {
                setIsSearching(true);
            } else {
                setIsSearching(false);
            }
        }
    }, [title, data]);

    return (
        <Collapsible initialState={initialState} title={item.collapseTitle} index={index}>
            <>
                {showFilter && (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <DoctSearchInput
                            placeholder="Search..."
                            name="filterSearch"
                            // label="Search Name"
                            id="filterSearch"
                            control={control}
                            isErrors={errors}
                            validationRules={{}}
                        />
                    </form>
                )}
                <div className="collapsible-content">
                    {arrays.map((checkboxItem, i) => (
                        <>
                            <Checkbox
                                key={i}
                                onChange={handleChange}
                                id={checkboxItem.id}
                                name={checkboxItem.value}
                                category={item.filterKey}
                                count={checkboxItem.count}
                                isChecked={
                                    filters.some((check) => check[item.filterKey] === checkboxItem.value) || false
                                }
                                truncateOn={truncateOn}
                            />
                        </>
                    ))}
                    {isLoading && <span className="text-center">Loading. . .</span>}
                    {!isLoading && isMore && !isSearching && <div ref={setElement}></div>}
                </div>
            </>
        </Collapsible>
    );
};

export default SingleFilter;
