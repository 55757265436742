import React, { ReactElement } from 'react';

import { Controller, Control, FieldError } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { DoctIcon } from '@doct-react/core';

import './PasswordInput.scss';

interface Props {
    label: string;
    id: string;
    name: string;
    control: Control<Record<string, any>>;
    variant?: 'standard' | 'filled' | 'outlined';
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    isErrors?: DeepMap<Record<string, any>, FieldError>;
    touched?: DeepMap<Record<string, any>, true>;
    validationRules?: any;
    defaultValue?: any;
    showStar?: boolean;
    showPassword?: boolean;
}
interface State {
    password: string;
    showPassword: boolean;
}

export default function DoctPasswordInput({
    label = 'Password',
    id,
    name,
    variant = 'standard',
    disabled = false,
    readOnly = false,
    className,
    control,
    isErrors,
    touched,
    defaultValue,
    validationRules,
    showStar,
    showPassword = false,
    ...rest
}: Props): ReactElement {
    const [values, setValues] = React.useState<State>({
        password: defaultValue,
        showPassword,
    });

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
    };

    return (
        <>
            <Controller
                rules={validationRules}
                as={
                    <FormControl
                        required={showStar && Object.keys(validationRules).length >= 1}
                        error={isErrors[name] ? true : false}
                        variant={variant}
                        className={`doct-input doct-password-input${values.password ? ' doct-input-focused' : ''}${
                            readOnly ? ' doct-read-only-input' : ''
                        }${className ? ` ${className}` : ''}${disabled ? ' disabled' : ''}`}
                    >
                        <InputLabel htmlFor={id}>{label}</InputLabel>
                        <Input
                            id={id}
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <div
                                        onMouseDown={handleMouseDownPassword}
                                        onClick={handleClickShowPassword}
                                        style={{ marginRight: '5px', cursor: 'pointer' }}
                                    >
                                        {values.showPassword ? (
                                            <DoctIcon width="24" height="24" name="eyeView" />
                                        ) : (
                                            <DoctIcon width="24" height="24" name="eyeViewOff" />
                                        )}
                                    </div>
                                </InputAdornment>
                            }
                        />
                        {isErrors[name] && <FormHelperText id={id}>{isErrors[name]?.message}</FormHelperText>}
                    </FormControl>
                }
                defaultValue={defaultValue}
                name={name}
                control={control}
                {...rest}
            />
        </>
    );
}
