/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { DoctTypography } from '@doct-react/core';
import DoctModal from '../../Modal/DoctModal';
import '../Static.scss';

interface DisclaimerPolicyProps {
    handleClose: () => void;
    open: boolean;
}
const DisclaimerPolicy: React.FC<DisclaimerPolicyProps> = ({ handleClose, open }) => {
    const [doctUrls, setDoctUrls] = useState(null);

    useEffect(() => {
        setDoctUrls(typeof window !== 'undefined' && window.DoctUrls ? window.DoctUrls : null);
    }, []);

    return (
        <DoctModal
            className="privacy-policy-modal static-modal"
            title="Disclaimer Policy"
            width={572}
            showFooter={false}
            open={open}
            handleClose={handleClose}
        >
            <DoctTypography variant="body2" className="text-grey-600">
                Our Disclaimer was last updated on 20 May 2022
            </DoctTypography>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Interpretation and Definitions
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <u>Interpretation </u> <br />
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear in
                singular or in plural.
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                <u>Definitions </u>
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
                For the purposes of this Disclaimer:
            </DoctTypography>
            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    <b> "Company" </b>(referred to as either "the Company", "We", "Us" or "Our" in this Disclaimer)
                    refers to Docthub Health Tech Private Limited
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b> "Service"</b> refers to the Website{' '}
                    <a href={`${doctUrls?.doct_brand_url}`} target="_blank" rel="noreferrer">
                        {' '}
                        www.docthub.com
                    </a>{' '}
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b> "You" </b> means the individual accessing the Service, or the company, or other legal entity on
                    behalf of which such individual is accessing or using the Service, as applicable.
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    <b>"Website"</b> refers to [DOCTHUB], accessible from www.docthub.com
                </DoctTypography>
            </ul>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                General Disclaimer
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                The information contained on the Service is for general information purposes only. The Company assumes
                no responsibility for errors or omissions in the contents of the Service. In no event shall the Company
                be liable for any special, direct, indirect, consequential, or incidental damages or any damages
                whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection
                with the use of the Service or the contents of the Service. The Company reserves the right to make
                additions, deletions, or modifications to the contents on the Service at any time without prior notice.
                The Company does not warrant that the Service is free of viruses or other harmful components.
            </DoctTypography>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                User Generated Content Disclaimer
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                The Service can offer its users to list or create their content or information include but not limited
                to job postings, course information, events details, blogs, news bulletin, educational materials,
                information or video. Such content is the views, opinions, positions or strategies expressed by the
                individual user or representative of any legal entity. Those content which is providing comments are
                user’s alone and don not necessarily reflect the views, opinions, positions or strategies of Company or
                any employee thereof. Company makes no representations as to accuracy, completeness, corrections,
                suitability or validity of any information generated by its Users and will not liable for any errors,
                omissions, or delays in this information or any losses, injuries, or damages arising from its display or
                use. Use of the advice and information contained herein is at your sole choice and risk. You should not
                rely on this information as a substitute for, nor does it replace, professional advice. Always consult
                your legal or professional advisor for further surety and clarity. By using this information, You are
                agreeing to accept full responsibility for your decisions and agreeing to hold harmless the Company.
            </DoctTypography>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                External Links Disclaimer
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                The Service may contain links to external websites that are not provided or maintained by or in any way
                affiliated with the Company. Please note that the Company does not guarantee the accuracy, relevance,
                timeliness, or completeness of any information on these external websites.
            </DoctTypography>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Fair Use Disclaimer
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                The Company may use copyrighted material which has not always been specifically authorized by the
                copyright owner. The Company is making such material available for criticism, comment, news reporting,
                teaching, scholarship, or research. The Company believes this constitutes a "fair use" of any such
                copyrighted material as provided the Copyright law. If You wish to use copyrighted material from the
                Service for your own purposes that go beyond fair use, You must obtain permission from the copyright
                owner.
            </DoctTypography>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Views Expressed Disclaimer
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                The Service may contain views and opinions which are those of the authors and do not necessarily reflect
                the official policy or position of any other author, agency, organization, employer or company,
                including the Company. Comments published by users are their sole responsibility and the users will take
                full responsibility, liability and blame for any libel or litigation that results from something written
                in or as a direct result of something written in a comment. The Company is not liable for any comment
                published by users and reserves the right to delete any comment for any reason whatsoever.
            </DoctTypography>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                No Responsibility Disclaimer
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                The information on the Service is provided with the understanding that the Company is not herein engaged
                in rendering legal, accounting, tax, or other professional advice and services. As such, it should not
                be used as a substitute for consultation with professional accounting, tax, legal or other competent
                advisers. In no event shall the Company or its suppliers be liable for any special, incidental,
                indirect, or consequential damages whatsoever arising out of or in connection with your access or use or
                inability to access or use the Service.
            </DoctTypography>

            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                "Use at Your Own Risk" Disclaimer
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600">
                All information in the Service is provided "as is", with no guarantee of completeness, accuracy,
                timeliness or of the results obtained from the use of this information, and without warranty of any
                kind, express or implied, including, but not limited to warranties of performance, merchantability and
                fitness for a particular purpose. The Company will not be liable to You or anyone else for any decision
                made or action taken in reliance on the information given by the Service or for any consequential,
                special or similar damages, even if advised of the possibility of such damages.
            </DoctTypography>
            <DoctTypography variant="body2" className="font-weight-bold text-grey-600">
                Contact Us
            </DoctTypography>

            <DoctTypography variant="body2" className="text-grey-600 ">
                If you have any questions about this Disclaimer, You can contact Us:
            </DoctTypography>

            <ul className="static-list static-list-item">
                <DoctTypography variant="list" className="text-grey-600">
                    By visiting this page on our website:
                    <a href={`${doctUrls?.doct_brand_url}/contact-us`} target="_blank" rel="noreferrer">
                        https://www.docthub.com/contact-us
                    </a>
                </DoctTypography>
                <DoctTypography variant="list" className="text-grey-600">
                    By sending us an email:<a href="mailto:office@docthub.com ">office@docthub.com </a>
                </DoctTypography>
            </ul>
        </DoctModal>
    );
};

export default DisclaimerPolicy;
