import React from 'react';
import { DoctButton, DoctTypography } from '@doct-react/core';
import { FeatureBoxResponsive } from './Components';

import './DoctNavigationMenuEnterpriseAppResponsive.scss';

// interface DoctNavigationMenuEnterpriseAppResponsiveProps {
//     //
// }

const DoctNavigationMenuEnterpriseResponsive: React.FC = ({}) => {
    const productList = [
        {
            href: window?.DoctUrls?.doct_recruiters_url,
            title: 'Recruiter Dashboard',
            description: 'Recruiter Dashboard',
            iconName: 'jobsEnterpriseFilled',
        },
        {
            href: window?.DoctUrls?.doct_institutes_url,
            title: 'Institute Dashboard',
            description: 'Institute Dashboard',
            iconName: 'courseEnterpriseFilled',
        },
        {
            href: window?.DoctUrls?.doct_event_organizers,
            title: 'Events Dashboard',
            description: 'Events Dashboard',
            iconName: 'eventEnterpriseFilled',
        },
        {
            href: `${window?.DoctUrls?.doct_membership_url}`,
            title: 'Membership Management',
            description: 'Association Management Solutions',
            iconName: 'membershipOutline',
        },
    ];

    return (
        <div className="navigation-menu responsive-navigation-menu bg-white">
            <div className="responsive-navigation-menu-enterprise pb-4 d-flex justify-content-center flex-wrap mx-auto">
                {productList.map((item) => {
                    return (
                        item?.href && (
                            <FeatureBoxResponsive
                                {...item}
                                key={item?.href}
                                className={`feature-box-fixed-width mb-3`}
                            />
                        )
                    );
                })}
                <FeatureBoxResponsive
                    href="/"
                    title="Enterprise Home"
                    iconName="enterpriseFilled"
                    className={`feature-box-fixed-width mb-3`}
                />
                <a
                    href={`${window.DoctUrls?.doct_enterprise_url}/dashboard`}
                    className="responsive-navigation-menu-go-to-dashboard d-flex flex-column align-items-center mt-sm-2"
                >
                    <DoctTypography variant="textLabel2" className="text-grey-600">
                        Go to your Enterprise Dashboard
                    </DoctTypography>
                    <div className="navigation-menu-btn-primary700">
                        <DoctButton text="Enterprise Login >" size="medium" />
                    </div>
                </a>
            </div>
        </div>
    );
};

export default DoctNavigationMenuEnterpriseResponsive;
