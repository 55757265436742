import React, { useEffect, useState } from 'react';
import { DoctTypography } from '@doct-react/core';
import dayjs from 'dayjs';

import './EventDayCounter.scss';

const EventDayCounter = ({ startDate = dayjs(), eventStartDate }) => {
    const [time, setTime] = useState<any>({});

    useEffect(() => {
        if (!eventStartDate) {
            return;
        }
        let seconds = dayjs(eventStartDate, 'YYYY-MM-DD').diff(dayjs(), 'seconds');
        setInterval(() => {
            const d = Math.floor(seconds / (3600 * 24));
            const h = Math.floor((seconds % (3600 * 24)) / 3600);
            const m = Math.floor((seconds % 3600) / 60);
            const s = Math.floor(seconds % 60);
            setTime({
                d,
                h,
                m,
                s,
            });
            seconds--;
        }, 1000);
    }, [eventStartDate]);
    const { d, h, m, s } = time;

    return (
        <div className="event-day-counter mx-auto text-center text-white">
            <div className="event-day-counter-col event-day-col-days d-inline-block p-1 p-sm-3">
                <DoctTypography variant="subtitle1" className="my-1">
                    {d}
                </DoctTypography>
                <DoctTypography variant="textLabel2" className="mt-sm-2">
                    Days
                </DoctTypography>
            </div>
            <div className="event-day-counter-col d-inline-block p-1 p-sm-3">
                <DoctTypography variant="subtitle1" className="my-1">
                    {h}
                </DoctTypography>
                <DoctTypography variant="textLabel2" className="mt-sm-2">
                    Hours
                </DoctTypography>
            </div>
            <div className="event-day-counter-col d-inline-block p-1 p-sm-3">
                <DoctTypography variant="subtitle1" className="my-1">
                    {m}
                </DoctTypography>
                <DoctTypography variant="textLabel2" className="mt-sm-2">
                    Minutes
                </DoctTypography>
            </div>
            <div className="event-day-counter-col event-day-col-seconds d-inline-block p-1 p-sm-3">
                <DoctTypography variant="subtitle1" className="my-1">
                    {s}
                </DoctTypography>
                <DoctTypography variant="textLabel2" className="mt-sm-2">
                    Seconds
                </DoctTypography>
            </div>
        </div>
    );
};

export default EventDayCounter;
