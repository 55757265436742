// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

export default function ListIcon({ fill }: { fill: string }): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="rounded_list_alt-24px" opacity="0.6">
                <path id="Path_1239" data-name="Path 1239" d="M0,0H24V24H0Z" fill="none" />
                <path
                    id="Path_1240"
                    data-name="Path 1240"
                    d="M10,7.667h3.111a.778.778,0,0,0,0-1.556H10a.778.778,0,1,0,0,1.556Zm0,3.111h3.111a.778.778,0,0,0,0-1.556H10a.778.778,0,1,0,0,1.556Zm0,3.111h3.111a.778.778,0,0,0,0-1.556H10a.778.778,0,1,0,0,1.556ZM6.111,6.111H7.667V7.667H6.111Zm0,3.111H7.667v1.556H6.111Zm0,3.111H7.667v1.556H6.111ZM16.222,3H3.778A.78.78,0,0,0,3,3.778V16.222A.78.78,0,0,0,3.778,17H16.222A.78.78,0,0,0,17,16.222V3.778A.78.78,0,0,0,16.222,3Zm-.778,12.444H4.556V4.556H15.444Z"
                    transform="translate(2 2)"
                    fill={`${fill ? fill : '#00a0c0'}`}
                />
            </g>
        </svg>
    );
}
