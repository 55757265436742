export const listItems = [
    {
        icon: 'brandIcon',
        name: 'My Account',
        url: '/my-account',
    },
    {
        icon: 'accountProfile',
        name: 'My Profile',
        url: '/profile',
    },
    {
        icon: 'coursesApp',
        name: 'My Courses',
        url: 'courses/my-courses',
    },
    {
        icon: 'jobsApp',
        name: 'My Jobs',
        url: 'jobs/my-jobs',
    },
    {
        icon: 'eventsApp',
        name: 'My Events',
        url: 'events/my-events',
    },
    {
        icon: 'setting',
        name: 'Settings',
        url: 'settings/login-security',
        color: '#717171',
    },
];
