import React, { ReactElement } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import './DoctLoading.scss';

interface Props {
    type?: string;
    spinnerSize?: number;
}

export default function DoctLoading({ type, spinnerSize = 20 }: Props): ReactElement {
    return type === 'linear' ? (
        <LinearProgress />
    ) : (
        <CircularProgress style={{ width: `${spinnerSize}px`, height: `${spinnerSize}px` }} />
    );
}
