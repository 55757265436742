// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mappedOptions = (options) => {
    return options.map((code) => {
        return {
            ...code,
            value: code.label,
            label: `${code.name} ${code.label}`,
        };
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sortString = (str) => {
    if (!str) return '';
    return str.toString().toLowerCase().split(' ').join('');
};
