import { DoctIcon } from '@doct-react/core';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from 'react';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import { FieldError } from 'react-hook-form';

type SearchInputProps = {
    onInputChangeHandler: (val) => void;
    onChangeHandler: any;
    inputValue: string;
    value: any;
    options: { title: string; value?: string }[];
    name: string;
    placeholder?: string;
    inverseBackground?: boolean;
    noRadial?: boolean;
    onClearHandler?: any;
    bgWhite?: boolean;
    hideSearchIcon?: boolean;
    label?: string;
    isErrors?: DeepMap<Record<string, any>, FieldError>;
    disableOnEnterFormSubmit?: boolean;
    onEnterPressed?: (e) => void;
    autoCompleteProps?: any;
};

const SearchInput: React.FC<SearchInputProps> = ({
    inputValue,
    value,
    onInputChangeHandler,
    onChangeHandler,
    options = [],
    name,
    placeholder,
    inverseBackground = false,
    noRadial,
    onClearHandler,
    bgWhite,
    hideSearchIcon,
    label,
    isErrors,
    disableOnEnterFormSubmit = false,
    onEnterPressed = (e) => null,
    autoCompleteProps = {},
}) => {
    const [classNameState, setClassNameState] = useState([]);

    useEffect(() => {
        const classNameList = ['doct-input', 'controlled-search', 'search-input'];

        if (inverseBackground && !bgWhite) {
            classNameList.push('search-input-grey');
        }

        if (noRadial) {
            classNameList.push('no-radial');
        }

        if (bgWhite) {
            classNameList.push('search-input-white');
        }

        setClassNameState(classNameList);
    }, [inverseBackground]);

    return (
        <div className={classNameState.join(' ')}>
            <Autocomplete
                freeSolo
                filterOptions={(options) => options}
                value={value}
                clearIcon={
                    <div className="cutsom-close-icon" onClick={onClearHandler}>
                        <DoctIcon width="20" name="close" />
                    </div>
                }
                onChange={(event: any, newValue: any) => {
                    onChangeHandler(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    if (!!!inputValue && newInputValue == ' ') {
                        return;
                    }
                    onInputChangeHandler(newInputValue);
                }}
                id={name}
                options={options.map((option) => option.title)}
                renderInput={(params) => (
                    <TextField
                        label={label}
                        {...params}
                        error={isErrors && isErrors[name] ? true : false}
                        helperText={isErrors && isErrors[name]?.message}
                        name={name}
                        placeholder={placeholder}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            startAdornment: hideSearchIcon ? null : (
                                <InputAdornment position="start">
                                    <DoctIcon name="search" className="text-grey-600" width="24" height="24" />
                                </InputAdornment>
                            ),
                            onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                    if (disableOnEnterFormSubmit) {
                                        e.preventDefault();
                                    }

                                    if (onEnterPressed) {
                                        onEnterPressed(e);
                                    }
                                }
                            },
                        }}
                        variant="standard"
                        {...autoCompleteProps}
                    />
                )}
            />
        </div>
    );
};
export default SearchInput;
