/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DoctIcon, DoctTypography } from '@doct-react/core';
import React, { useEffect, useRef } from 'react';
import './DoctAppBarDropdown.scss';

interface DoctAppBarDropdownProps {
    menuLinks: any;
    brandingLink: any;
    setMenuOpen: any;
    onMenuClosed?: any;
}

export function useOuterClick(callback: any): any {
    const callbackRef = useRef<any>(); // initialize mutable ref, which stores callback
    const innerRef = useRef<HTMLHeadingElement>(); // returned to client, who marks "border" element

    // update cb on each render, so second useEffect has access to current value
    useEffect(() => {
        callbackRef.current = callback;
    });

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
        function handleClick(e) {
            if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) callbackRef.current(e);
        }
    }, []); // no dependencies -> stable click listener

    return innerRef; // convenience for client (doesn't need to init ref himself)
}

function ApplicationProduct({ product: { name = '', subTitle = '', link = '', icon = 'apps' } = {} }) {
    return (
        <a className="d-flex align-items-center app-bar-dropdown-app-link" href={link}>
            <DoctIcon width="42" height="42" name={icon} />
            <DoctTypography variant="subtitle1" className="my-0 text-grey-800 ml-3">
                {name}
                <br />
                <DoctTypography variant="caption1" className="my-0 text-grey-500 letter-spacing-014">
                    {subTitle}
                </DoctTypography>
            </DoctTypography>
        </a>
    );
}

const DoctAppBarDropdown = ({ menuLinks, brandingLink, setMenuOpen, onMenuClosed }: DoctAppBarDropdownProps): any => {
    const innerRef = useOuterClick((e) => {
        setMenuOpen(false);
        onMenuClosed(e);
    });

    return (
        <div className="app-bar-dropdown position-absolute bg-white" ref={innerRef}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-7">
                        {menuLinks?.map(({ name, link, icon, subTitle }) => {
                            return (
                                <>
                                    <ApplicationProduct product={{ name, icon, link, subTitle }} />
                                </>
                            );
                        })}
                    </div>
                    <div className="col-sm-5">
                        <div className="app-bar-dropdown-side-area">
                            {brandingLink?.map(({ name, link, subTitle }) => {
                                return (
                                    <a
                                        href={link}
                                        key={link}
                                        className="app-bar-dropdown-branding-link d-inline-block w-100"
                                    >
                                        <DoctTypography variant="subtitle1" className="my-0 text-primary">
                                            {name}
                                            <br />
                                            <DoctTypography
                                                variant="caption1"
                                                className="my-0 text-grey-500 letter-spacing-014"
                                            >
                                                {subTitle}
                                            </DoctTypography>
                                        </DoctTypography>
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <span
                onClick={(e) => {
                    setMenuOpen(false);
                    onMenuClosed(e);
                }}
                className="app-bar-dropdown-side-close position-absolute d-sm-none text-grey-500"
            >
                <DoctIcon width="24" height="24" name="close" />
            </span>
        </div>
    );
};

export default DoctAppBarDropdown;
