import React from 'react';
import { DoctIcon, DoctTypography } from '@doct-react/core';

import { DoctUserMenuEnterpriseProps, MenuInfoAreaProps, MenuLinkProps } from './interfaceDoctUserMenuEnterprise';

// // import DefaultAvatarImage from '../../../assests/images/default-avatar.png';
import './DoctUserMenuEnterprise.scss';
import MenuMUI from '@mui/material/Menu';

const MenuLayout: React.FC = ({ children }) => {
    return <div className="doct-user-menu-enterprise  overflow-hidden">{children}</div>;
};

const MenuInfoArea: React.FC<MenuInfoAreaProps> = ({ defaultImgAvatarEnterprise, userDetails }) => {
    return (
        <div className="doct-user-menu-enterprise-info-area text-center bg-white">
            <div className="doct-user-menu-enterprise-avatar-size mx-auto overflow-hidden">
                <img
                    src={userDetails?.tenant?.organizationLogoUrl || defaultImgAvatarEnterprise}
                    alt={'Avatar'}
                    className={'d-block m-auto w-100 h-100 object-fit-cover'}
                />
            </div>
            <DoctTypography variant="subtitle2" className="doct-user-menu-enterprise-name-gap">
                {userDetails?.tenant?.organizationName}
            </DoctTypography>
            <DoctTypography variant="caption1" className="text-grey-600 my-0" fontWeight="medium">
                {`Admin: ${userDetails?.tenant?.name}`}
            </DoctTypography>
            <br />
            <DoctTypography variant="caption2" className="text-grey-600 my-0" letterSpacing={0.22}>
                {`ID: ${userDetails?.userNumber}`}
            </DoctTypography>
        </div>
    );
};

const MenuLink: React.FC<MenuLinkProps> = ({ el: Element, href, onClick, icon, title, hideRightIcon }) => {
    const elProps = {};
    if (href) elProps['href'] = href;
    return (
        <Element
            {...elProps}
            onClick={onClick}
            className={'doct-user-menu-enterprise-link d-flex align-items-center px-3 cursor-pointer'}
        >
            <span className="reset-line-height">
                <DoctIcon name={icon} width={'24'} height={'24'} />
            </span>
            <DoctTypography
                variant={'textLabel1'}
                className="doct-user-menu-enterprise-link-title-gap w-100 text-grey-800"
            >
                {title}
            </DoctTypography>
            {!hideRightIcon && (
                <DoctIcon name={'right'} width={'24'} height={'24'} className={'ml-auto text-grey-400'} />
            )}
        </Element>
    );
};

const DoctUserMenuEnterprise: React.FC<DoctUserMenuEnterpriseProps> = ({
    handleLogout,
    anchorEl,
    open,
    userDetails,
    onClose,
    defaultImgAvatarEnterprise,
}) => {
    const linksArray = [
        {
            title: 'Enterprise Dashboard',
            icon: 'dashboardGray',
            href: `${window.DoctUrls.doct_dashboard_url}/dashboard`,
        },
        {
            title: 'Account settings',
            icon: 'accountSettings',
            href: `${window.DoctUrls.doct_dashboard_url}/dashboard/settings/profile`,
        },
    ];
    return (
        <MenuMUI
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="doct-user-menu-enterprise-menu-wrapper"
            PopoverClasses={{ paper: 'doct-user-menu-enterprise-menu-paper w-100' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuLayout>
                <MenuInfoArea userDetails={userDetails} defaultImgAvatarEnterprise={defaultImgAvatarEnterprise} />
                {linksArray.map(({ title, icon, href }) => {
                    return <MenuLink {...{ title, icon, href }} key={title} el={'a'} />;
                })}
                <MenuLink title={'Log out'} icon={'logout'} el={'div'} onClick={handleLogout} hideRightIcon />
            </MenuLayout>
        </MenuMUI>
    );
};

export default DoctUserMenuEnterprise;
