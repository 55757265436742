import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { DoctIcon, DoctTypography } from '@doct-react/core';
import { Tooltip } from '@mui/material';

interface ISideBarProfile {
    name: string;
    image: string;
    professionalTitle: string;
}

const SideBarProdfile: React.FC<ISideBarProfile> = ({ name, image, professionalTitle }) => {
    return (
        <ListItem disablePadding className="bg-white doct-user-menu-profile">
            <ListItemButton disableRipple className="doct-user-menu-profile-list-item-button">
                <div className="d-flex justify-content-between align-items-center w-100 p-2">
                    <div className="d-flex align-items-center w-100">
                        <ListItemIcon className="d-flex justify-content-end mr-3">
                            {!image ? (
                                <DoctIcon width="56" height="56" name="accountProfile" />
                            ) : (
                                <img src={image} alt={name} width={56} height={56} className="doct-user-menu-img" />
                            )}
                        </ListItemIcon>
                        <div className="d-flex flex-column align-items-start">
                            <DoctTypography
                                variant="subtitle1"
                                className="d-flex justify-content-end mr-3 my-0 text-grey-800"
                            >
                                {name}
                            </DoctTypography>
                            <DoctTypography
                                variant="caption2"
                                textTransform="normal"
                                className="d-flex justify-content-end text-grey-600 mr-3 my-0"
                            >
                                <Tooltip title={professionalTitle} sx={{ vertical: 'center', horizontal: 'center' }}>
                                    <span className="doct-user-menu-typography">
                                        {professionalTitle?.length >= 60
                                            ? professionalTitle?.substring(0, 60) + '...'
                                            : professionalTitle}
                                    </span>
                                </Tooltip>
                            </DoctTypography>
                        </div>
                    </div>
                    <div>
                        <DoctIcon width="24" height="24" fill="" name="doctClose" />
                    </div>
                </div>
            </ListItemButton>
        </ListItem>
    );
};

export default SideBarProdfile;
