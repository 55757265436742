import React, { createContext, useReducer } from 'react';

const LOADING = 'LOADING';
const LOADED = 'LOADED';
// /Technically we're not showing items per page.
//It's more like items to show at a time.
const itemsPerPage = 7;

const reducer = (state, action) => {
    switch (action.type) {
        case LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case LOADED:
            const startParam = state.startParam + action.payload.dataChunk.length;
            return {
                ...state,
                isLoading: false,
                data: [...state.data, ...action.payload.dataChunk],
                startParam: startParam,
                isMore: startParam !== action.payload.dataLength,
            };

        default:
            throw new Error("Don't understand action.");
    }
};

export const InfiniteContext = createContext({
    data: [],
    isLoading: false,
    isMore: false,
    loadDataChunk: () => {},
});

interface InifiniteScrollProps {
    children: any;
    sourceData: any;
}

const InfiniteScrollProvider: React.FC<InifiniteScrollProps> = ({ children, sourceData }) => {
    const [state, dispatch] = useReducer(reducer, {
        isLoading: false,
        isMore: true,
        data: [],
        startParam: 0,
    });

    const { isLoading, isMore, data, startParam } = state;

    //Update: If this function is only used by one component, it makes more sense
    //to locate it in that component, and just pass dispatch instead.
    const loadDataChunk = () => {
        dispatch({ type: LOADING });

        // //Simulate asynchronous request with setTimeout
        // setTimeout(() => {
        //     //sourceData represents the entire data set.
        //     //What we want is a subset.
        //     //Initially, startParam is 0.
        //     //Thus startParam + itemsPerPage would be 10

        // }, 1000);

        const dataChunk = sourceData.slice(startParam, startParam + itemsPerPage);

        //Once we have the dataChunk, call dispatch again...
        //LOADED will cause the reducer to set isLoading: false.
        //Moreover, the payload/dataChunk will be added onto state.data
        //startParam will be adjusted accordingly
        //And isMore will be reevaluated.
        const payload = {
            dataLength: sourceData.length,
            dataChunk: dataChunk,
        };
        dispatch({ type: LOADED, payload: payload });
    };

    return (
        <InfiniteContext.Provider value={{ isLoading, isMore, data, loadDataChunk }}>
            {children}
        </InfiniteContext.Provider>
    );
};

export default InfiniteScrollProvider;
