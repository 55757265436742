import { DoctIcon } from '@doct-react/core';
import React from 'react';
import {
    BaseNavigationMenu,
    HeadingAndContent,
    LayoutNavigationMenu,
    LinkItem,
    NavigationItemColumn,
    NavigationMenuContainer,
} from './Components/Components';

interface DoctNavigationMenuUserAppBrandingProps {
    onClosePressed: any;
    setMenuOpen?: any;
    branding?: boolean;
    adjustSpacing?: number;
}

interface UserAppNavigationPropsProps {
    branding?: boolean;
}

export const UserAppNavigation: React.FC<UserAppNavigationPropsProps> = ({ branding }) => {
    return (
        <>
            <NavigationItemColumn>
                <HeadingAndContent heading={branding ? 'Our offerings' : 'Go to'}>
                    {window?.DoctUrls?.doct_course_url && (
                        <LinkItem
                            iconName="coursesApp"
                            title="Courses"
                            description="Find healthcare institutes"
                            href={window.DoctUrls.doct_course_url}
                        />
                    )}
                    {window?.DoctUrls?.doct_jobs_url && (
                        <LinkItem
                            iconName="jobsApp"
                            title="Jobs"
                            description="Search for Healthcare Jobs."
                            href={window.DoctUrls.doct_jobs_url}
                        />
                    )}
                    {window?.DoctUrls?.doct_event_url && (
                        <LinkItem
                            iconName="eventsApp"
                            title="Events"
                            description="Conferences, Workshops & more."
                            href={window.DoctUrls.doct_event_url}
                        />
                    )}
                </HeadingAndContent>
                <div
                    className={`navigation-menu-divider-horizontal navigation-menu-divider-horizontal-gap-midium navigation-menu-divider d-sm-none w-100`}
                />
            </NavigationItemColumn>
            <NavigationItemColumn>
                <div className="d-flex flex-sm-column justify-content-around align-items-stretch h-100 w-100 ">
                    <div className={'navigation-menu-tools'}>
                        <HeadingAndContent heading="Tools">
                            {window?.DoctUrls?.doct_log_book_url && (
                                <LinkItem
                                    iconName="logbookOutline"
                                    title="Logbook"
                                    description="Track your professional journey"
                                    href={window.DoctUrls.doct_log_book_url}
                                />
                            )}
                        </HeadingAndContent>
                    </div>
                    <div className={`navigation-menu-divider navigation-menu-divider-vertical`} />
                    <div className={`mt-auto navigation-menu-knowledge`}>
                        <HeadingAndContent heading="Knowledge">
                            <div className="d-flex">
                                {window?.DoctUrls?.doct_blogs_url && (
                                    <LinkItem
                                        iconName="blogsIconOutline"
                                        title="Blogs"
                                        href={window.DoctUrls.doct_blogs_url}
                                        tiny
                                    />
                                )}
                                {window?.DoctUrls?.doct_news_url && (
                                    <LinkItem
                                        iconName="newsOutline"
                                        title="News"
                                        href={window.DoctUrls.doct_news_url}
                                        tiny
                                    />
                                )}
                            </div>
                        </HeadingAndContent>
                    </div>
                </div>
            </NavigationItemColumn>
        </>
    );
};

const DoctNavigationMenuUserAppUserApp: React.FC<DoctNavigationMenuUserAppBrandingProps> = ({
    onClosePressed,
    setMenuOpen,
    branding,
    adjustSpacing,
}) => {
    return (
        <NavigationMenuContainer adjustSpacing={adjustSpacing}>
            <BaseNavigationMenu onClosePressed={onClosePressed} setMenuOpen={setMenuOpen}>
                <LayoutNavigationMenu branding={branding}>
                    <span
                        className={`position-absolute navigation-menu-close d-sm-none`}
                        onClick={(e) => {
                            onClosePressed(e);
                            setMenuOpen(false);
                        }}
                    >
                        <DoctIcon name="close" width="24" height="24" className="text-grey-600" />
                    </span>
                    <UserAppNavigation branding={branding} />
                </LayoutNavigationMenu>
            </BaseNavigationMenu>
        </NavigationMenuContainer>
    );
};

export default DoctNavigationMenuUserAppUserApp;
