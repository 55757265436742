import React, { ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import DoctActionMenu from '../ActionMenu/DoctActionMenu';
import DoctLoading from '../Loadings/DoctLoading';

import { selectPaginationProps, selectRowProps, statusClasses } from './Utils';

import './DoctTable.scss';

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
    classes: any;
    // numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    order: Order;
    orderBy: string | number;
    rowCount: number;
    sortable?: boolean;
    columns?: any;
    onColumnClickHandler: (event: React.MouseEvent<unknown>) => void;
}

interface TableProps {
    sortable?: boolean;
    loading?: boolean;
    rowsData: Record<string, unknown>[];
    totalRowTitle?: string;

    onColumnClickHandler: (data) => void;
    onActionMenuClickHandler: (data) => void;

    paginationProps?: {
        rowsPerPageProps?: number;
        perPageOptionProps?: [number, number, number];
        pagination?: boolean;
        totalCount?: number;
        onPageChangeHandler?: (data) => void;
    };
    rowProps: {
        onRowClick: boolean;
        onRowClickHandler?: (data) => void;
    };

    columns: {
        id: string;
        numeric: boolean;
        disablePadding: boolean;
        label: string;
        sorting: boolean;
        align?: 'left' | 'right' | 'inherit' | 'center' | 'justify';
        classes?: string;
        isStatus?: boolean;
        width?: number;
    }[];
}

const useStyles = makeStyles(() =>
    createStyles({
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort, sortable, columns, onColumnClickHandler } = props;
    const createSortHandler = (property, columnData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
        const isAsc = orderBy === property && order === 'asc';
        columnData.sortingDirection = isAsc ? 'desc' : 'asc';
        onColumnClickHandler(columnData);
    };

    return (
        <TableHead>
            <TableRow>
                {columns &&
                    columns.map((headCell) => (
                        <React.Fragment key={headCell.id}>
                            {sortable ? (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.align ? headCell.align : 'left'}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                    style={{ width: headCell.width ? headCell.width : '' }}
                                >
                                    {headCell.sorting && headCell.id !== 'actions' ? (
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id, headCell)}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    ) : (
                                        headCell.label
                                    )}
                                </TableCell>
                            ) : (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.align ? headCell.align : 'left'}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                >
                                    {headCell.label}
                                </TableCell>
                            )}
                        </React.Fragment>
                    ))}
            </TableRow>
        </TableHead>
    );
}

export default function EnhancedTable(props: TableProps): ReactElement {
    const classes = useStyles();
    const {
        sortable,
        columns,
        rowsData,
        paginationProps,
        loading,
        rowProps,
        onColumnClickHandler,
        totalRowTitle,
        onActionMenuClickHandler,
    } = props;
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState<Record<string, unknown>[]>([]);
    const [page, setPage] = React.useState(0);

    const { rowsPerPageOption, perPageOptionPropsOption, pagination, totalCount, onPageChangeHandler } =
        selectPaginationProps(paginationProps);

    const { onRowClick, onRowClickHandler } = selectRowProps(rowProps);

    const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOption);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event: React.MouseEvent<unknown>, row) => {
        setSelected(row);
        {
            onRowClick && onRowClickHandler(row);
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        onPageChangeHandler(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowsData && rowsData.length - page * rowsPerPage);

    const rowData = (row, index) => {
        const labelId = `enhanced-table-checkbox-${index}`;
        return (
            <TableRow hover={onRowClick} onClick={(event) => handleClick(event, row)} key={index}>
                {columns &&
                    columns.map((column) => (
                        <TableCell
                            className={`${column.classes ? column.classes : ''}${
                                column.isStatus ? statusClasses[row[column.id].toLowerCase()] : ''
                            }`}
                            align={column.align ? column.align : 'left'}
                            id={labelId}
                            scope="row"
                            key={column.id}
                            padding={column.disablePadding ? 'none' : 'normal'}
                        >
                            {column.id === 'actions' ? (
                                <DoctActionMenu
                                    className="light-grey-icon"
                                    onClick={(column) => onActionMenuClickHandler(column)}
                                    options={row.actions}
                                />
                            ) : (
                                row[column.id]
                            )}
                        </TableCell>
                    ))}
            </TableRow>
        );
    };

    const totalRowsCount = totalCount ? totalCount : rowsData && rowsData.length;

    return (
        <>
            {loading ? (
                <DoctLoading type="linear" />
            ) : (
                <>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="m-0 total-rows-data">
                            {totalRowTitle} <span className="total-count-separator"></span>
                            <span className="total-count">{totalRowsCount}</span>
                        </p>
                        {pagination && (
                            <TablePagination
                                rowsPerPageOptions={perPageOptionPropsOption}
                                component="div"
                                count={totalRowsCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                // onPageChange={() => void 0}
                            />
                        )}
                    </div>
                    <TableContainer>
                        <Table className="doct-table" aria-labelledby="tableTitle" aria-label="enhanced table">
                            <EnhancedTableHead
                                onColumnClickHandler={onColumnClickHandler}
                                classes={classes}
                                // numSelected={0}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rowsData && rowsData.length}
                                sortable={sortable}
                                columns={columns}
                            />
                            <TableBody>
                                {pagination
                                    ? rowsData &&
                                      rowsData
                                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                          .map((row, index) => {
                                              return rowData(row, index);
                                          })
                                    : rowsData &&
                                      rowsData.map((row, index) => {
                                          return rowData(row, index);
                                      })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </>
    );
}
