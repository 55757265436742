import React, { useEffect } from 'react';
import DoctModal from '../Modal/DoctModal';
import VerifyMobileNumberLayout from './VerifyMobileNumberLayout';
import DoctForm from '../Form/DoctForm';
import './VerifyMobileNumber.scss';

interface Props {
    onVerifyPressed?: (value) => void;
    onCancelPressed?: () => void;
    mobileCountryCode?: any;
    mobileNumber?: string;
    open?: boolean;
    isSubmitButtonDisable?: boolean;
}

const DoctVerifyMobileNumberModal: React.FC<Props> = ({
    onVerifyPressed = (value) => null,
    onCancelPressed = () => null,
    mobileCountryCode,
    mobileNumber,
    open = false,
    isSubmitButtonDisable = false,
}) => {
    const { reset, control, errors, handleSubmit } = DoctForm({
        mode: 'onChange',
    });

    useEffect(() => {
        reset({
            mobileNumber,
            mobileCountryCode: '+91',
        });
    }, [mobileNumber, mobileCountryCode]);

    const handleVerifyPressed = () => {
        handleSubmit((value) => {
            onVerifyPressed(value);
        })();
    };

    return (
        <DoctModal
            open={open}
            iconName=""
            primaryBtnText={mobileNumber ? 'Verify Now' : 'Add & Verify'}
            title={mobileNumber ? 'Verify Mobile number' : 'Add Mobile number'}
            width={360}
            className="verify-mobile-modal"
            handlePrimaryButtonClick={handleVerifyPressed}
            handleClose={onCancelPressed}
            isPrimaryBtnDisable={isSubmitButtonDisable}
            disableBackdropClick={true}
        >
            <VerifyMobileNumberLayout control={control} errors={errors} mobileNumber={mobileNumber} />
        </DoctModal>
    );
};

export default DoctVerifyMobileNumberModal;
