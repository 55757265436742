import React from 'react';
import Skeleton from '@mui/material/Skeleton';

import '../Skeleton.scss';

export const SkeletonSearchbox = ({ ...props }: any) => {
    return <Skeleton variant="rectangular" height={46} {...props} />;
};

export const SkeletonChips = ({ ...props }: any) => {
    return <Skeleton variant="rectangular" height={32} width={'160px'} className={'doct-chip mb-6px'} {...props} />;
};

export const SkeletonDivider = ({ className = '' }) => {
    return <Skeleton variant="rectangular" height={1} width={'100%'} className={className} />;
};

export const SkeletonFilterWithResult = ({ ...props }) => {
    return (
        <div className="result-skeleton-area">
            <div className="d-flex justify-content-between">
                <Skeleton variant="rectangular" width={'40px'} height={16} />
                <Skeleton variant="rectangular" width={'62px'} height={16} />
            </div>
            <div className="chip-skeleton-wrapper">
                <SkeletonChips />
            </div>
        </div>
    );
};

export const SkeletonCollapsibleHeading = () => {
    return (
        <div className="d-flex justify-content-between collapsible-skeleton-heading">
            <Skeleton variant="rectangular" width={'150px'} height={16} />
            <Skeleton variant="rectangular" width={'18px'} height={16} className={'border-radius'} />
        </div>
    );
};

export const SkeletonCheckboxWithLabel = () => {
    return (
        <div className="d-flex align-items-center checkbox-label-skeleton-area">
            <Skeleton variant="rectangular" width={'18px'} height={18} className={'mr-2 ml-1'} />
            <Skeleton variant="rectangular" width={'100%'} height={18} />
        </div>
    );
};

export const SkeletonFilterCollapsible = () => {
    return (
        <>
            <SkeletonDivider className="mt-2" />
            <SkeletonCollapsibleHeading />
            <SkeletonSearchbox />
            <SkeletonCheckboxWithLabel />
            <SkeletonCheckboxWithLabel />
            <SkeletonCheckboxWithLabel />
            <SkeletonCheckboxWithLabel />
            <SkeletonCheckboxWithLabel />
            <SkeletonCheckboxWithLabel />
            <SkeletonCheckboxWithLabel />
        </>
    );
};

export const SkeletonTabViewVertical = () => {
    return (
        <>
            <Skeleton variant="rectangular" width={'100%'} height={52} className={'border-radius'} />
            <div className="vertical-tab-list-item-area d-flex align-items-center">
                <Skeleton variant="rectangular" width={80} height={18} className={'border-radius'} />
            </div>
            <div className="vertical-tab-list-item-area d-flex align-items-center">
                <Skeleton variant="rectangular" width={90} height={18} className={'border-radius'} />
            </div>
            <div className="vertical-tab-list-item-area d-flex align-items-center">
                <Skeleton variant="rectangular" width={100} height={18} className={'border-radius'} />
            </div>
            <div className="vertical-tab-list-item-area d-flex align-items-center">
                <Skeleton variant="rectangular" width={100} height={18} className={'border-radius'} />
            </div>
            <div className="vertical-tab-list-item-area d-flex align-items-center">
                <Skeleton variant="rectangular" width={70} height={18} className={'border-radius'} />
            </div>
        </>
    );
};
