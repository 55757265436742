import React from 'react';
import './DoctLoading.scss';

interface AnimationLoadingProps {}

const AnimationLoading: React.FC<AnimationLoadingProps> = () => {
    return (
        <div className="page-loading">
            <svg
                className="mb-4 container"
                xmlns="http://www.w3.org/2000/svg"
                width="50.039"
                height="40.657"
                viewBox="0 0 50.039 40.657"
            >
                <path
                    id="ARTWORK"
                    d="M3.768,40.657H0V.25H9.268q10.926,0,17.167,5.3t6.252,14.53q0,9.61-6,15.087-6.027,5.49-16.525,5.492ZM7.509,33.7h2.624c4.773,0,8.457-1.165,11.081-3.468,2.6-2.3,3.908-5.573,3.908-9.789,0-4.243-1.367-7.488-4.131-9.816C18.228,8.32,14.32,7.155,9.268,7.155H7.509ZM44.456,18.582V5.823H31.616V0H50.039V18.582Z"
                    fill="#00a0c0"
                />
            </svg>
            <div className="animated_line border-radius">
                <div className="inner_line_blue_color"></div>
            </div>
        </div>
    );
};

export default AnimationLoading;
