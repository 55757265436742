import React, { useState, useEffect } from 'react';
import { DoctButton, DoctIcon, DoctTypography } from '@doct-react/core';

import qs from 'qs';
import { MenuItem } from '@mui/material';
import { MenuLinkProps } from '../DoctUserMenu/DoctUserMenuEnterprise/interfaceDoctUserMenuEnterprise';
import MenuMUI from '@mui/material/Menu';

interface LoginSignUpButtonProps {
    handleLoginRedirection: () => any;
    additionalButtonText?: string;
    onAdditionalButtonClicked?: () => any;
    brandingVariant?: boolean;
    onCreateAccountPressed?: () => void;
    continueUrl?: string;
}
export const handleRedirection = (path: string, continueUrl?: string): void => {
    if (typeof window !== 'undefined') {
        const query = {
            continue_url: continueUrl || window.location.href,
        };
        window.location.href = `${window?.DoctUrls.doct_account_url}/${path}?${qs.stringify(query)}`;
    }
};

declare global {
    interface Window {
        DoctUrls: any;
    }
}

const LoginSignUpButton: React.FC<LoginSignUpButtonProps> = ({
    handleLoginRedirection,
    additionalButtonText,
    onAdditionalButtonClicked,
    brandingVariant = false,
    onCreateAccountPressed,
    continueUrl,
}) => {
    const [doctUrls, setDoctUrls] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        setDoctUrls(typeof window !== 'undefined' && window.DoctUrls ? window.DoctUrls : null);
    }, []);

    const MenuLayout: React.FC = ({ children }) => {
        return <div className="overflow-hidden menu-dropdown-width">{children}</div>;
    };

    const [menuOpen, setMenuOpen] = useState(false);

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setMenuOpen(false);
    };
    const linksArray = [
        {
            title: window && window.innerWidth > 767 ? 'User account' : 'Create User account',
            subTitle: 'To find jobs, courses, events',
            onClick: () => {
                if (onCreateAccountPressed) {
                    onCreateAccountPressed();
                } else {
                    handleRedirection('create-account', continueUrl);
                }
            },
        },

        {
            title: window && window.innerWidth > 767 ? 'Enterprise Account' : 'Create Enterprise Account',
            subTitle: 'For Recruiters, Institutes, Event organisers.',
            href: `${doctUrls?.doct_enterprise_account_url}`,
        },
    ];

    const MenuLink: React.FC<MenuLinkProps> = ({ el: Element, href, onClick, title, subTitle, hideRightIcon }) => {
        const elProps = {};
        if (href) elProps['href'] = href;
        return (
            <MenuItem>
                <Element
                    {...elProps}
                    onClick={onClick}
                    className={'doct-user-menu-enterprise-link d-flex align-items-center cursor-pointer w-100'}
                >
                    <div className="dropdown-text-width">
                        <DoctTypography variant={'textLabel1'} className="text-grey-800 m-0 font-weight-medium">
                            {title}
                        </DoctTypography>
                        <DoctTypography variant={'textLabel2'} className="text-grey-400 m-0 text-wrap-balance">
                            {subTitle}
                        </DoctTypography>
                    </div>
                    {!hideRightIcon && (
                        <DoctIcon name={'right'} width={'24'} height={'24'} className={'ml-auto text-grey-400'} />
                    )}
                </Element>
            </MenuItem>
        );
    };

    const MenuDropDown: React.FC = ({ children }) => {
        return (
            <MenuMUI
                id="basic-menu"
                className="menu-reset login-create-account-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {children}
            </MenuMUI>
        );
    };

    if ((window && window.innerWidth < 768) || (window && window.innerWidth < 1024 && brandingVariant)) {
        return (
            <div className="ml-auto">
                <DoctButton
                    variant="text"
                    text="Login/Signup"
                    size="medium"
                    type="secondary"
                    onButtonClickHandler={handleButtonClick}
                    className="narrow-spacing"
                />
                <MenuDropDown>
                    <MenuLayout>
                        <MenuItem
                            onClick={() => {
                                handleLoginRedirection();
                            }}
                            className="text-grey-800 doct-user-menu-enterprise login-divider-line"
                        >
                            Login
                            <DoctIcon name={'right'} width={'24'} height={'24'} className={'ml-auto text-grey-400'} />
                        </MenuItem>
                        {linksArray.map(({ title, subTitle, onClick, href }) => (
                            <MenuLink {...{ title, subTitle, onClick, href }} key={title} el={'a'} />
                        ))}
                    </MenuLayout>
                </MenuDropDown>
            </div>
        );
    }

    return (
        <div className="d-flex ml-auto align-items-center auth-button">
            <DoctButton
                onButtonClickHandler={handleButtonClick}
                size="medium"
                type="primary"
                variant="text"
                text="Create account"
                className="narrow-spacing"
            />
            <span className="pipe mx-1"></span>
            <DoctButton
                onButtonClickHandler={handleLoginRedirection}
                size="medium"
                type="secondary"
                variant="text"
                text="Log in"
                className="narrow-spacing mr-3"
            />

            <MenuDropDown>
                <MenuLayout>
                    {linksArray.map(({ title, subTitle, onClick, href }) => (
                        <MenuLink {...{ title, subTitle, onClick, href }} key={title} el={'a'} />
                    ))}
                </MenuLayout>
            </MenuDropDown>

            {additionalButtonText && (
                <DoctButton
                    onButtonClickHandler={onAdditionalButtonClicked}
                    size="medium"
                    type="primary"
                    text={additionalButtonText}
                    icon="right"
                    variant="outlined"
                    iconPosition="right"
                    className="d-none d-sm-none d-md-none d-lg-flex"
                />
            )}
        </div>
    );
};

export default LoginSignUpButton;
